<template>
  <b-field label="Pharmacy" horizontal class="label-medium">
    <div class="columns is-mobile">
      <b-autocomplete
        class="column"
        name="pharmacy"
        v-model="name"
        field="fullName"
        ref="autocomplete"
        @blur="blur"
        :data="filteredList"
        placeholder="Search / Select / Add Pharmacy"
        @select="selectValue"
        clearable
        open-on-focus
        :disabled="disabled"
      >
        <template v-slot:header>
          <a @click="addClicked">
            <span> Add new... </span>
          </a>
        </template>
        <template v-slot="props">
          {{ props.option.fullName }}
        </template>
        <template v-slot:empty> {{ name ? "No Results for " + name : "No Results" }}</template>
      </b-autocomplete>
      <p v-if="mainName" class="column">
        {{ mainName }}<br />{{ mainTel }}
        <b-button v-if="!disabled" class="is-text is-small" @click="edit">Edit</b-button>
      </p>
    </div>
  </b-field>
</template>

<script>
import PharmacyEditDialog from "@/components/dialogs/PharmacyEditDialog";

export default {
  props: ["pharmacyId", "disabled"],
  data() {
    return {
      name: "",
      selectedPharmacy: null
    };
  },
  computed: {
    list() {
      return this.$store.getters["pharmacies/pharmacies"];
    },
    filteredList() {
      const searchName = this.name ? this.name.toLowerCase() : null;
      if (!searchName) return this.list;
      const filtered = this.list.filter(option => {
        return option.fullName.toLowerCase().indexOf(searchName) >= 0;
      });
      // clear any selection if no match
      if (!filtered.length) this.$refs.autocomplete.setHovered(null);

      return filtered;
    },
    mainName() {
      return this.selectedPharmacy?.pharmacyName;
    },
    mainTel() {
      return this.selectedPharmacy?.workNumber ? "Tel: " + this.selectedPharmacy.workNumber : "";
    }
  },
  watch: {
    pharmacyId(newValue) {
      this.selectFromId(newValue);
    }
  },
  async created() {
    await this.loadList();
  },
  methods: {
    blur() {
      // if we have entered something
      if (this.name) {
        // but no match, click new
        if (!this.filteredList.length) this.addClicked();
        else this.selectValue(this.filteredList[0]); // select first
      }
    },
    newPharmacy() {
      return { name: "", pharmacyId: null };
    },
    async loadList() {
      this.isLoading = true;
      await this.$store.dispatch("pharmacies/loadPharmacies");
      this.isLoading = false;
      this.selectFromId(this.pharmacyId);
    },
    selectFromId(pharmacyId) {
      if (!this.list.length) return; // if not loaded yet
      this.selectedPharmacy = this.list.find(l => l.pharmacyId === pharmacyId);
      if (!this.selectedPharmacy && !pharmacyId) this.selectedPharmacy = this.newPharmacy();
      this.selectValue(this.selectedPharmacy);
    },
    selectValue(value) {
      this.name = value ? value.fullName : "";
      this.$emit("select", value?.pharmacyId);
    },
    addClicked() {
      // if existing, then clear name to enter new one
      if (this.pharmacyId) {
        this.name = "";
        this.selectedPharmacy = this.newPharmacy();
      }
      this.$refs.autocomplete.setSelected(null);
      this.edit();
    },
    edit() {
      this.$buefy.modal.open({
        parent: this,
        component: PharmacyEditDialog,
        hasModalCard: true,
        props: { pharmacy: this.selectedPharmacy, name: this.name },
        events: { selectPharmacy: this.selectValue }
      });
    }
  }
};
</script>
