<template>
  <div>
    <XyeaFormEdit
      icon="family-tree"
      url="nextofkin/"
      entityName="Contact"
      :form="form"
      :id="form.nextOfKinId"
      @close="$emit('close')"
      @add="refresh"
      @update="refresh"
      :validForm="validForm"
      :disabled="readonly"
      :dialog="true"
      :loadOnOpen="false"
    >
      <b-field label="Name" horizontal>
        <person-title v-model="form.title" :disabled="readonly" />
        <b-input v-model="form.firstName" placeholder="First Name" required :disabled="readonly" v-focus />
        <b-input v-model="form.lastName" placeholder="Last Name" required :disabled="readonly" />
      </b-field>
      <phone-edit
        label="Mobile Phone"
        :required="true"
        :phoneNumber="form.mobileNumber"
        :countryCode="form.mobileCountry"
        @updatePhone="updateMobilePhone"
        class="field"
        ref="mobileNumber"
        :disabled="readonly"
      />
      <b-field label="Email" horizontal>
        <b-input v-model="form.email" type="email" :disabled="readonly" />
      </b-field>
      <b-field label="Contact Order" horizontal>
        <EnumDropDown v-model="form.order" :data="contactOrderLookup" :disabled="readonly" />
      </b-field>
      <b-field label="Relationship" horizontal>
        <lookup-select-add v-model="form.relationship" title="Relationship" :lookupType="lookupTypeRelationship" :disabled="readonly" />
      </b-field>
      <b-field label="Responsibility" horizontal>
        <lookup-select-add
          v-model="form.responsibility"
          title="Responsibility"
          :lookupType="lookupTypeResponsibility"
          :disabled="readonly"
        />
      </b-field>
      <b-field label="Contactable" horizontal>
        <lookup-select-add v-model="form.contactable" title="Contactable" :lookupType="lookupTypeContactable" :disabled="readonly" />
      </b-field>
      <address-edit v-model="form" class="field" :disabled="readonly" :required="false" />
    </XyeaFormEdit>
  </div>
</template>

<script>
import AddressEdit from "@/components/address/AddressEdit";
import PersonTitle from "@/components/profile/PersonTitle";
import PhoneEdit from "@/components/profile/PhoneEdit";
import PhoneMixin from "@/mixins/phoneMixin";
import XyeaFormEdit from "@/components/XyeaFormEdit";
import EnumDropDown from "@/components/EnumDropDown";
import ContactOrder from "@/enums/contactOrder";
import LookupSelectAdd from "@/components/LookupSelectAdd.vue";
import PermissionsEnum from "@/enums/permissions";

export default {
  components: { AddressEdit, PersonTitle, PhoneEdit, XyeaFormEdit, EnumDropDown, LookupSelectAdd },
  mixins: [PhoneMixin],
  props: ["nok", "residentId"],

  data() {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      contactOrderLookup: ContactOrder.Lookup,
      lookupTypeRelationship: LookupSelectAdd.Enum.Relationship,
      lookupTypeContactable: LookupSelectAdd.Enum.Contactable,
      lookupTypeResponsibility: LookupSelectAdd.Enum.Responsibility
    };
  },
  computed: {
    validForm() {
      return !this.mobileIsError;
    },
    readonly() {
      return !this.$store.getters.hasPermission(PermissionsEnum.ResidentUpdate);
    }
  },
  methods: {
    getClearFormObject() {
      this.clearPhonesErrors();

      return {
        nexkOfKinId: 0,
        residentId: this.residentId,
        title: null,
        firstName: null,
        lastName: null,
        email: null,
        address1: null,
        address2: null,
        city: null,
        county: null,
        country: null,
        postcode: null,
        mobileNumber: null,
        mobileCountry: null,
        relationship: null,
        order: ContactOrder.Enum.Primary,
        responsibilty: null,
        contactable: null
      };
    },
    refresh(details) {
      this.$emit("refresh", details);
    }
  },
  mounted() {
    if (this.nok?.nextOfKinId) this.form = { ...this.nok };
  }
};
</script>
