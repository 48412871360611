import { GetSelectLookup } from "./common";

const HowReceivedEnum = {
  Telephone: 1,
  Email: 2,
  InPerson: 3,
  Website: 4,
  SocialMedia: 5
};

const HowReceivedLookup = ["", "Telephone", "Email", "In Person", "Website Form", "Social Media"];

export default {
  Enum: HowReceivedEnum,
  SelectLookup: GetSelectLookup(HowReceivedEnum, HowReceivedLookup),
  Lookup: HowReceivedLookup
};
