<template>
  <div>
    <XyeaFormEdit
      icon="statusChange"
      url="statuschange/"
      entityName="Status Change"
      :form="form"
      :id="form.statusChangeId"
      :formPostData="getFormPostData"
      @close="$emit('close')"
      @add="v => $emit('add', v)"
      :disabled="disabled"
      :dialog="true"
      :loadOnOpen="false"
      class="label-medium"
    >
      <div v-if="!disabled" class="mb-3">
        <ButtonSmall
          v-if="currentStatus === residentStatus.Enum.Enquiry"
          @click="addShortList"
          icon="arrow-right"
          label="Add to Short List"
          :selected="form.status === residentStatus.Enum.ShortList"
        />
        <ButtonSmall
          v-if="currentStatus === residentStatus.Enum.ShortList"
          @click="removeShortList"
          icon="arrow-left"
          label="Remove from Short List"
          :selected="form.status === residentStatus.Enum.Enquiry"
        />

        <ButtonSmall
          v-if="currentStatus === residentStatus.Enum.Enquiry || currentStatus === residentStatus.Enum.ShortList"
          @click="admit"
          icon="arrow-right"
          label="Admit"
          :selected="form.status === residentStatus.Enum.Client"
        />
        <ButtonSmall v-if="currentStatus === residentStatus.Enum.Client" @click="dying" icon="emoticon-sick" label="Dying" />
        <ButtonSmall
          v-if="currentStatus === residentStatus.Enum.Client || currentStatus === residentStatus.Enum.ActiveDying"
          @click="deceased"
          icon="grave-stone"
          label="Deceased"
          :selected="form.status === residentStatus.Enum.Deceased"
        />
        <ButtonSmall
          v-if="
            currentStatus === residentStatus.Enum.Client ||
            currentStatus === residentStatus.Enum.ActiveDying ||
            currentStatus === residentStatus.Enum.Deceased
          "
          @click="discharge"
          icon="location-exit"
          label="Discharge"
          :selected="form.status === residentStatus.Enum.Discharged"
        />
        <ButtonSmall
          v-if="currentStatus === residentStatus.Enum.Enquiry || currentStatus === residentStatus.Enum.ShortList"
          @click="cancel"
          icon="close-circle-outline"
          label="Cancel"
          class="ml-6"
          :selected="form.status === residentStatus.Enum.Cancelled"
        />
        <ButtonSmall
          v-if="canReAdmit"
          @click="admit"
          icon="arrow-right"
          label="Re-Admit"
          class="ml-6"
          :selected="canReAdmit && form.status === residentStatus.Enum.Client"
        />
      </div>

      <ValidationProvider :rules="{ statusChangeRequired: form.status }" v-slot="{ errors }">
        <b-field horizontal class="hiddenFieldLabel" :type="{ 'is-danger': errors[0] }" :message="errors">
          <b-field v-model="form.status" class="hiddenField" />
        </b-field>
      </ValidationProvider>

      <b-field :label="!form.statusChangeId ? 'New Status' : 'Status'" horizontal>
        {{ residentStatus.Lookup[form.status] }}
      </b-field>

      <DateSelector
        v-model="form.statusChangeDate"
        :disabled="disabled"
        :label="dateLabel"
        required
        customPosition="is-over3"
        :past="true"
      />

      <template v-if="form.status === residentStatus.Enum.Client || form.status === residentStatus.Enum.Discharged">
        <b-field :label="form.status === residentStatus.Enum.Client ? 'Admit From' : 'Discharge To'" horizontal>
          <EnumDropDown v-model="form.admitFromType" :data="admitfrom.SelectLookup" required :disabled="disabled" />
        </b-field>
        <b-field v-if="form.admitFromType === admitfrom.Enum.CareHome" label="Care Home" horizontal>
          <LookupSelectById v-model="form.admitFromId" :lookupType="lookupTypes.Carehomes" :disabled="disabled" title="Care Home" />
        </b-field>
        <b-field v-if="form.admitFromType === admitfrom.Enum.Hospital" label="Hospital" horizontal>
          <LookupSelectById v-model="form.admitFromId" :lookupType="lookupTypes.Hospitals" :disabled="disabled" title="Hospital" />
        </b-field>
        <b-field v-if="form.admitFromType === admitfrom.Enum.Other" label="Other" horizontal>
          <LookupSelectById
            v-model="form.admitFromId"
            :lookupType="lookupTypes.AdmitFromOther"
            :disabled="disabled"
            title="Other Location"
          />
        </b-field>
        <b-field label="Contact" horizontal>
          <b-input v-model="form.contact" :disabled="disabled" required />
        </b-field>
      </template>

      <!-- We show beds when doing new status, ie not when viewing older status -->
      <CurrentBed
        v-if="!form.statusChangeId"
        :residentId="residentId"
        :locationId="locationId"
        :bedsInfo="bedsInfo"
        @refresh="refreshBeds"
        :disabled="disabled"
      />

      <template v-if="form.status === residentStatus.Enum.Deceased">
        <b-field label="Cause of Death" horizontal>
          <b-input v-model="form.deathCause" :disabled="disabled" />
        </b-field>
        <b-field label="Who Present" horizontal>
          <b-input v-model="form.deathWhoPresent" :disabled="disabled" />
        </b-field>
      </template>

      <VoiceNotesIconField v-model="form.notes" :disabled="disabled" notesRef="notes" />
    </XyeaFormEdit>
  </div>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import VoiceNotesIconField from "@/components/VoiceNotesIconField";
import ResidentStatus from "@/enums/residentStatus";
import AdmitFrom from "@/enums/admitFrom";
import ButtonSmall from "@/components/ButtonSmall.vue";
import LookupSelectById from "@/components/LookupSelectById.vue";
import EnumDropDown from "@/components/EnumDropDown.vue";
import DateSelector from "@/components/DateSelector.vue";
import CurrentBed from "@/components/profile/CurrentBed.vue";

import { required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import dates from "@/common/dates.js";

extend("statusChangeRequired", {
  ...required,
  message: "Please select a new status from the options above"
});

export default {
  components: { XyeaFormEdit, VoiceNotesIconField, ButtonSmall, LookupSelectById, EnumDropDown, DateSelector, CurrentBed },
  props: {
    residentId: { type: Number, required: true },
    locationId: { type: Number, required: true },
    currentStatus: { type: Number },
    disabled: { type: Boolean },
    statusChange: { type: Object },
    bedsInfo: { type: Array, default: () => [] }
  },
  data() {
    return {
      isLoading: false,
      residentStatus: ResidentStatus,
      admitfrom: AdmitFrom,
      lookupTypes: LookupSelectById.Enum,
      form: this.getClearFormObject()
    };
  },
  computed: {
    canReAdmit() {
      return this.currentStatus === this.residentStatus.Enum.Cancelled || this.currentStatus === this.residentStatus.Enum.Discharged;
    },
    dateLabel() {
      return ResidentStatus.DateLabelLookup[this.form.status ?? 0];
    }
  },
  methods: {
    getClearFormObject() {
      return {
        residentId: this.$store.getters["resident/residentId"],
        status: null,
        statusChangeDate: new Date(),
        contact: null,
        admitFromType: null,
        admitFromId: null,
        notes: null,
        deathCause: null,
        deathWhoPresent: null
      };
    },
    addShortList() {
      this.form.status = ResidentStatus.Enum.ShortList;
    },
    removeShortList() {
      this.form.status = ResidentStatus.Enum.Enquiry;
    },
    admit() {
      this.form.status = ResidentStatus.Enum.Client;
    },
    dying() {
      this.form.status = ResidentStatus.Enum.ActiveDying;
    },
    deceased() {
      this.form.status = ResidentStatus.Enum.Deceased;
    },
    discharge() {
      this.form.status = ResidentStatus.Enum.Discharged;
    },
    cancel() {
      this.form.status = ResidentStatus.Enum.Cancelled;
    },
    refreshBeds() {
      this.$emit("refreshBeds");
    },
    getFormPostData() {
      // return new form object with Dates only
      return { ...this.form, statusChangeDate: dates.formatYYYYMMDD(this.form.statusChangeDate) };
    }
  },
  mounted() {
    if (this.statusChange?.statusChangeId) {
      this.form = { ...this.statusChange };
    }
    this.form.residentId = this.residentId;
  }
};
</script>
