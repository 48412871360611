<template>
  <XyeaFormEdit
    :icon="icon"
    url="medicalhistory/"
    :loadOnOpen="!fullData"
    @mapServerData="mapServerData"
    :entityName="entityName"
    :form="data"
    :id="residentId"
    :id2="historyType"
    :id3="assessmentId"
    @close="$emit('close')"
    @update="$emit('refresh')"
    :dialog="true"
    :useCustomSave="useCustomSave"
    @save="v => $emit('save', v)"
  >
    <template v-for="category in data.categories">
      <div class="columns" :key="'c' + category.category">
        <div class="column sectionHeader">
          {{ category.category }}
          <ClickIcon
            icon="add"
            class="is-pulled-right"
            noPadding
            :title="'Add New ' + category.category + ' condition'"
            tooltipPosition="is-left"
            @click="addCondition(category)"
          />
        </div>
      </div>
      <template v-for="condition in category.conditions">
        <div class="columns row-space-narrow" :key="condition.lookupId">
          <div class="column is-6 has-text-right has-text-weight-bold field-nested">
            <p v-if="condition.lookupId > 0" class="field-label">{{ condition.name }}</p>
            <b-field v-else>
              <b-input v-model="condition.name" required :ref="'name' + condition.lookupId" />
            </b-field>
          </div>
          <div class="column is-1 field-nested">
            <p class="field-label">
              <b-checkbox v-model="condition.hasCondition" />
            </p>
          </div>
          <div class="column is-2">
            <b-field>
              <b-numberinput
                v-if="condition.hasCondition"
                v-model="condition.year"
                :controls="false"
                required
                placeholder="Year"
                :min="1900"
                :max="thisYear"
                maxlength="4"
              />
            </b-field>
          </div>
          <div class="column field-nested" v-if="condition.hasCondition">
            <b-input
              v-if="condition.showNotes || condition.notes"
              v-model="condition.notes"
              placeholder="Notes"
              :ref="'notes' + condition.lookupId"
            />
            <ClickIcon v-else icon="notes" title="Add notes" @click="showNotes(condition)" class="field-label" noPadding />
          </div>
          <div v-if="condition.lookupId <= 0" class="field-nested">
            <ClickIcon icon="delete" title="Delete condition" tooltipPosition="is-left" @click="deleteCondition(category, condition)" />
          </div>
        </div>
      </template>
    </template>
  </XyeaFormEdit>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import MedicalHistory from "@/enums/medicalHistoryType";
import IconsMixin from "@/mixins/iconsMixin.js";
import ClickIcon from "@/components/ClickIcon.vue";

export default {
  mixins: [IconsMixin],
  components: { XyeaFormEdit, ClickIcon },
  props: {
    residentId: { type: Number, required: true },
    historyType: { type: Number, required: true },
    assessmentId: { type: Number },
    useCustomSave: { type: Boolean }, // use custom save func, used for PreAssessment, as we may not yet have assessmentId
    fullData: { type: Object } // Full Data passed in, so not retrieved again from DB
  },
  data() {
    return {
      isLoading: false,
      thisYear: new Date().getFullYear(),
      newConditionId: 0,
      data: {
        id: this.residentId,
        categories: []
      }
    };
  },
  computed: {
    icon() {
      switch (this.historyType) {
        case MedicalHistory.Enum.Surgical:
          return "hospital-box-outline";
        case MedicalHistory.Enum.Allergies:
          return "peanut-off-outline";
        default:
          return "medical-bag";
      }
    },
    entityName() {
      switch (this.historyType) {
        case MedicalHistory.Enum.Surgical:
          return "Surrgical History";
        case MedicalHistory.Enum.Allergies:
          return "Allergies";
        default:
          return "Medical History";
      }
    }
  },
  methods: {
    mapServerData(data) {
      this.data = { ...data };
    },
    showNotes(condition) {
      condition.showNotes = true;
      this.setFocus("notes" + condition.lookupId);
    },
    setFocus(field) {
      this.$nextTick(function () {
        this.$refs[field][0].focus();
      });
    },
    addCondition(category) {
      category.conditions.push({
        lookupId: this.newConditionId,
        category: category.category,
        categoryOrderId: category.categoryOrderId,
        name: null,
        orderId: 999,
        hasCondition: true,
        medicalHistoryId: 0,
        historyType: this.historyType,
        year: null,
        notes: null,
        showNotes: false
      });
      this.setFocus("name" + this.newConditionId);
      this.newConditionId--;
    },
    deleteCondition(category, condition) {
      category.conditions = category.conditions.filter(i => i !== condition);
    }
  },
  mounted() {
    // if we have data passed in (from PreAssessment Form), don't get it again
    // But it will get it for Resident Form, and for New PreAssessment
    if (this.fullData) {
      this.data = this.fullData;
    }
  }
};
</script>
