const ContactOrderEnum = {
  Primary: 1,
  Secondary: 2,
  Tertiary: 3,
  Other: 4
};

export default {
  Enum: ContactOrderEnum,
  Lookup: [
    { id: ContactOrderEnum.Primary, value: "Primary" },
    { id: ContactOrderEnum.Secondary, value: "Secondary" },
    { id: ContactOrderEnum.Tertiary, value: "Tertiary" },
    { id: ContactOrderEnum.Other, value: "Other" }
  ]
};
