<template>
  <b-field label="Status" horizontal class="field-nested">
    <div class="levelLeftMobile">
      <p class="unitLabel" :class="{ clickable: canEdit }" @click="viewStausChange">
        {{ residentStatus.Lookup[curentStatus] }}
      </p>
      <template v-if="canEdit">
        <ClickIcon icon="edit" @click="viewStausChange" title="View Status Info" />
        <ClickIcon icon="statusChange" @click="newStausChange" title="Change Status" />
      </template>
    </div>
  </b-field>
</template>

<script>
import ClickIcon from "@/components/ClickIcon.vue";
import ResidentStatus from "@/enums/residentStatus";
import ResidentStatusChangeDialog from "@/components/dialogs/ResidentStatusChangeDialog.vue";

export default {
  props: {
    curentStatus: { type: Number },
    residentId: { type: Number, required: true },
    currentStatusChange: { type: Object },
    bedsInfo: { type: Array, default: () => [] },
    locationId: { type: Number },
    disabled: { type: Boolean }
  },
  components: { ClickIcon },
  data() {
    return {
      residentStatus: ResidentStatus,
      modalDialog: null
    };
  },
  computed: {
    canEdit() {
      // resident must be added before we can change status
      return !this.disabled && this.residentId;
    }
  },
  watch: {
    // Props passed into modal are not reactive by default, so we need to forceUpdate when it changes
    // see https://github.com/buefy/buefy/issues/533
    bedsInfo(v) {
      if (this.modalDialog !== null) {
        this.modalDialog.props.bedsInfo = v;
        this.modalDialog.$forceUpdate();
      }
    }
  },
  methods: {
    viewStausChange() {
      if (this.canEdit) this.viewStausChangeDlg(this.currentStatusChange);
    },
    newStausChange() {
      this.viewStausChangeDlg();
    },

    viewStausChangeDlg(statusChange) {
      this.modalDialog = this.$buefy.modal.open({
        parent: this,
        component: ResidentStatusChangeDialog,
        hasModalCard: true,
        props: {
          statusChange: statusChange,
          residentId: this.residentId,
          locationId: this.locationId,
          currentStatus: this.curentStatus,
          disabled: statusChange != null, // currently can only view existing status, no edit
          bedsInfo: this.bedsInfo
        },
        events: {
          add: v => this.$emit("add", v),
          refreshBeds: () => this.$emit("refreshBeds")
        }
      });
    }
  }
};
</script>
