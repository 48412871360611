<template>
  <b-field label="Country" horizontal>
    <b-select placeholder="Select a country" :value="value" @input="v => $emit('input', v)" :required="required" :disabled="disabled">
      <option v-for="option in countries" :value="option.CountryCode" :key="option.CountryCode">
        {{ option.Country }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
import countryInfo from "./country";

export default {
  data() {
    return countryInfo;
  },
  props: {
    value: { type: String },
    disabled: { type: Boolean },
    required: { type: Boolean, default: true }
  },
  mounted() {
    // default country
    if (!this.value) this.$emit("input", this.$store.getters.defaultCountryCode);
  }
};
</script>
