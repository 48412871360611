<template>
  <!-- Selection option based on the lookup Id value, and allows adding a new one -->
  <b-field :message="message">
    <b-autocomplete
      :name="title"
      :placeholder="'Select ' + this.title"
      v-model="name"
      :keepFirst="true"
      @blur="blur"
      :data="filterNames"
      @select="selectByName"
      :clearable="!disabled"
      open-on-focus
      ref="autocomplete"
      autocomplete="off"
      :disabled="disabled"
      :required="required"
    >
      <template v-slot:header>
        <a @click="showAdd">
          <span> Add new... </span>
        </a>
      </template>

      <template v-slot:empty>No results for {{ name }}</template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import lookups from "@/enums/lookups.js";
export default {
  Enum: lookups.Enum,
  props: {
    value: { type: Number },
    lookupType: { type: Number, required: true },
    title: { type: String, required: true },
    message: { type: String },
    required: { type: Boolean },
    disabled: { type: Boolean },
    excludeList: { type: Array, default: () => [] }
  },
  data() {
    return {
      fetchingData: false,
      name: "",
      origSelectedId: null,
      newName: null
    };
  },
  computed: {
    dataArray() {
      if (!this.lookupType) return null;
      var lookups = this.$store.getters["lookups/getLookups"]({ lookupType: this.lookupType });
      // Get data on null, but not empty array as this indicates we tried but got no data
      if (lookups === null && !this.fetchingData) {
        this.getData();
      }
      return lookups;
    },
    filteredDataArray() {
      if (this.dataArray === null) return [];
      // remove any excluded ones, but our current one
      var filtered1 = [...this.dataArray];
      this.excludeList.forEach(i => {
        const index = filtered1.findIndex(l => l.lookupId === i);
        if (index >= 0 && filtered1[index].name !== this.name) {
          filtered1.splice(index, 1);
        }
      });

      const searchName = this.name ? this.name.toLowerCase() : null;
      if (!searchName) return filtered1;

      const filtered2 = filtered1.filter(option => {
        return option.name.toLowerCase().indexOf(searchName) >= 0;
      });
      // clear any selection if no match
      if (!filtered2.length && this.$refs.autocomplete) this.$refs.autocomplete.setHovered(null);
      return filtered2;
    },
    filterNames() {
      return this.filteredDataArray.map(l => l.name);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "selectById"
    },
    dataArray(n, o) {
      if (n?.length !== o?.length) {
        if (!o?.length && this.origSelectedId) {
          // when lookup first read, we may need to try select orginal value
          this.selectById(this.origSelectedId);
        } else {
          // when new data added, we need to reselect the current value so we get the id
          this.selectByName(this.name || this.newName);
        }
      }
    }
  },
  methods: {
    async getData() {
      this.fetchingData = true;
      await this.$store.dispatch("lookups/getLookups", { lookupType: this.lookupType });
      this.fetchingData = false;
    },
    blur() {
      // if we have entered something, but no match, click new
      if (this.name && !this.filteredDataArray.length) this.showAdd();
    },
    showAdd() {
      const refField = this.$refs.autocomplete;
      this.$buefy.dialog.prompt({
        message: "Add new " + this.title,
        inputAttrs: {
          maxlength: 20,
          value: this.name
        },
        confirmText: "Add",
        onConfirm: value => {
          this.$store.dispatch("lookups/addLookup", { name: value, lookupType: this.lookupType });
          refField.setSelected(value);
        },
        onCancel: () => {
          refField.setSelected("");
        }
      });
    },
    selectByName(name) {
      // find match
      const match = this.dataArray?.find(l => l.name === name)?.lookupId;
      // when adding new value, sometimes its not there yet before this gets calls, so we save it for later
      if (name && !match) this.newName = name;
      else this.newName = null;

      this.$emit("input", match);
    },
    selectById() {
      // find match
      const match = this.dataArray?.find(l => l.lookupId === this.value);
      if (match) {
        this.name = match.name;
        this.origSelectedId = null;
      } else {
        // maybe from no lookup data loaded yet, so we save off and try again after
        this.origSelectedId = this.value;
      }
    }
  }
};
</script>
