<template>
  <div class="microphoneContainer">
    <b-input
      :value="value"
      @input="updateText"
      :placeholder="placeholder"
      :required="required"
      type="textarea"
      :disabled="disabled"
      v-focus="focused"
      ref="inputArea"
    />
    <a v-if="!disabled" class="microphone" @click.prevent="startDictation"> <b-icon icon="microphone" /></a>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String },
    placeholder: { type: String },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    focused: { type: Boolean, default: false }
  },
  methods: {
    updateText(v) {
      this.$emit("input", v);
    },
    startDictation() {
      if (Object.prototype.hasOwnProperty.call(window, "webkitSpeechRecognition")) {
        // eslint-disable-next-line new-cap
        var recognition = new (window.SpeechRecognition ||
          window.webkitSpeechRecognition ||
          window.mozSpeechRecognition ||
          window.msSpeechRecognition)();

        recognition.lang = "en-GB";
        recognition.continuous = true;
        recognition.interimResults = false;
        recognition.maxAlternatives = 5;

        recognition.start();

        const fnUpdate = this.updateText;

        recognition.onresult = function (e) {
          fnUpdate(e.results[0][0].transcript);
          recognition.stop();
        };

        recognition.onerror = function (e) {
          recognition.stop();
        };
      } else {
        // not support info todo
      }
    }

    //     // set up

    //     var language = "en-US";
    //     showInfo("info_start");

    //     var final_transcript = "";
    //     var recognizing = false;
    //     var ignore_onend;
    //     var start_timestamp;

    //     var recognition;

    //     setUp();

    //         recognition.onstart = function() {
    //           recognizing = true;
    //           showInfo("info_speak_now");
    //           start_img.src = "//google.com/intl/en/chrome/assets/common/images/content/mic-animate.gif";
    //         };

    //         recognition.onerror = function(event) {
    //           if (event.error == "no-speech") {
    //             start_img.src = "//google.com/intl/en/chrome/assets/common/images/content/mic.gif";
    //             showInfo("info_no_speech");
    //             ignore_onend = true;
    //           }
    //           if (event.error == "audio-capture") {
    //             start_img.src = "//google.com/intl/en/chrome/assets/common/images/content/mic.gif";
    //             showInfo("info_no_microphone");
    //             ignore_onend = true;
    //           }
    //           if (event.error == "not-allowed") {
    //             if (event.timeStamp - start_timestamp < 100) {
    //               showInfo("info_blocked");
    //             } else {
    //               showInfo("info_denied");
    //             }
    //             ignore_onend = true;
    //           }
    //         };

    //         recognition.onend = function() {
    //           recognizing = false;
    //           if (ignore_onend) {
    //             return;
    //           }
    //           start_img.src = "//google.com/intl/en/chrome/assets/common/images/content/mic.gif";
    //           if (!final_transcript) {
    //             showInfo("info_start");
    //             return;
    //           }
    //           showInfo("");
    //           if (window.getSelection) {
    //             window.getSelection().removeAllRanges();
    //             var range = document.createRange();
    //             range.selectNode(document.getElementById("final_span"));
    //             window.getSelection().addRange(range);
    //           }
    //         };

    //         recognition.onresult = function(event) {
    //           var interim_transcript = "";
    //           if (typeof event.results == "undefined") {
    //             recognition.onend = null;
    //             recognition.stop();
    //             upgrade();
    //             return;
    //           }
    //           for (var i = event.resultIndex; i < event.results.length; ++i) {
    //             if (event.results[i].isFinal) {
    //               final_transcript += event.results[i][0].transcript;
    //             } else {
    //               interim_transcript += event.results[i][0].transcript;
    //             }
    //           }
    //           // final_transcript = capitalize(final_transcript);
    //           // final_span.innerHTML = linebreak(final_transcript);
    //           // interim_span.innerHTML = linebreak(interim_transcript);
    //           final_span.innerHTML = final_transcript;
    //           interim_span.innerHTML = interim_transcript;
    //         };
    //       }
    //     }

    //     function upgrade() {
    //       // tell user to upgrade &/or use Chrome
    //       start_button.style.visibility = "hidden";
    //       showInfo("info_upgrade");
    //     }

    //     // var two_line = /\n\n/g;
    //     // var one_line = /\n/g;
    //     // function linebreak(s) {
    //     //   return s.replace(two_line, '<p></p>').replace(one_line, '<br>');
    //     // }

    //     // var first_char = /\S/;
    //     // function capitalize(s) {
    //     //   return s.replace(first_char, function(m) { return m.toUpperCase(); });
    //     // }

    //     // start listening right away, so it's completely hands-free
    //     startButton(event);

    //     function startButton(event) {
    //       if (recognizing) {
    //         recognition.stop();
    //         return;
    //       }
    //       final_transcript = "";
    //       recognition.lang = language;
    //       recognition.start();
    //       ignore_onend = false;
    //       final_span.innerHTML = "";
    //       interim_span.innerHTML = "";
    //       start_img.src = "//google.com/intl/en/chrome/assets/common/images/content/mic-slash.gif";
    //       showInfo("info_allow");
    //       start_timestamp = event.timeStamp;
    //     }

    //     function showInfo(info_id) {
    //       // try: comment out the contents of this function

    //       if (info_id) {
    //         for (var child = info.firstChild; child; child = child.nextSibling) {
    //           if (child.style) {
    //             child.style.display = child.id == info_id ? "inline" : "none";
    //           }
    //         }
    //         info.style.visibility = "visible";
    //       } else {
    //         info.style.visibility = "hidden";
    //       }
    //     }
    //   }
    // },
  }
};
</script>

<style>
.microphoneContainer {
  position: relative;
}
.microphoneContainer input,
.microphoneContainer textarea {
  padding-right: 15px;
}
.microphone {
  position: absolute;
  bottom: 5px;
  right: 16px;
}
</style>
