<template>
  <b-field :label="label" horizontal>
    <b-input
      :value="value"
      @input="v => $emit('input', v)"
      type="textarea"
      :placeholder="placeholder"
      maxlength="1000"
      :disabled="disabled"
      :hasCounter="hasCounter"
      :required="required"
    />
  </b-field>
</template>

<script>
export default {
  props: {
    value: { type: String },
    placeholder: { type: String },
    disabled: { type: Boolean },
    label: { type: String, default: "Notes" },
    hasCounter: { type: Boolean, default: true },
    required: { type: Boolean, default: false }
  }
};
</script>
