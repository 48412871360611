<template>
  <section class="section is-main-section">
    <tiles>
      <card-component :title="formCardTitle" icon="account-edit" class="tile is-child">
        <form @keydown.enter.prevent.self ref="residentForm">
          <div class="tile">
            <div class="tile is-ancestor is-vertical">
              <div class="tile is-parent">
                <b-field class="tile is-child is-3" grouped>
                  <user-avatar :avatar="avatarUrl" class="is-big image has-max-width" :gender="form.gender" />
                  <ImagePicker
                    v-if="!readonly"
                    :id="form.residentId"
                    :imageType="imageTypeResident"
                    @new-image="newImage"
                    class="leftMarginOffset"
                  />
                </b-field>
                <div class="tile">
                  <div class="tile is-child">
                    <b-field label="Title" horizontal>
                      <person-title v-model="form.title" :disabled="readonly" />
                    </b-field>
                    <b-field label="First Name" horizontal>
                      <b-input v-model="form.firstName" placeholder="First Name" required expanded :disabled="readonly" />
                    </b-field>
                    <b-field label="Middle Name" horizontal>
                      <b-input v-model="form.middleName" placeholder="Middle Name" expanded :disabled="readonly" />
                    </b-field>
                    <b-field label="Surname" horizontal>
                      <b-input v-model="form.lastName" placeholder="Surname" required expanded :disabled="readonly" />
                    </b-field>
                    <b-field label="Known As" horizontal>
                      <b-input v-model="form.knownAs" :disabled="readonly" />
                    </b-field>
                  </div>
                </div>
              </div>
              <div class="tile is-child">
                <b-field label="Marital Status" horizontal class="label-long">
                  <lookup-select-add
                    v-model="form.maritalStatus"
                    title="Marital Status"
                    :lookupType="lookupTypes.MaritalStatus"
                    :disabled="readonly"
                  />
                </b-field>
                <SelectLocation
                  v-model="form.locationId"
                  :disabled="!canEditLocation"
                  :locationName="locationNameReadOnly"
                  :mainLocationsOnly="true"
                  required
                  class="label-long"
                />
                <b-field label="Service Type" horizontal class="label-long">
                  <EnumDropDown v-model="form.serviceType" :data="serviceTypeSelect" required :disabled="readonly" />
                </b-field>
                <ResidentStatusUpdate
                  :residentId="form.residentId"
                  :curentStatus="form.residentStatus"
                  :locationId="form.locationId"
                  :currentStatusChange="form.currentStatusChange"
                  :bedsInfo="form.bedsInfo"
                  @add="updateCurrentStatus"
                  @refreshBeds="refreshBedAssigments"
                  :disabled="readonly"
                  class="label-long"
                />
                <CurrentBed
                  v-if="form.residentId"
                  :residentId="form.residentId"
                  :locationId="form.locationId"
                  :bedsInfo="form.bedsInfo"
                  @refresh="refreshBedAssigments"
                  :disabled="readonly"
                  class="label-long"
                />
              </div>
            </div>

            <div class="tile is-parent">
              <div class="tile is-child">
                <DateSelector v-model="form.dob" :disabled="readonly" label="Date of Birth" required :isLoading="isLoading" :past="true" />
                <b-field label="Gender" horizontal class="field-nested">
                  <PersonGender v-model="form.medicalGender" message="for medical purposes" :disabled="readonly" />
                  <lookup-select-add
                    v-model="form.gender"
                    title="Gender"
                    :lookupType="lookupTypes.Gender"
                    message="preferred"
                    :disabled="readonly"
                  />
                </b-field>
                <b-field label="Email" horizontal>
                  <b-input v-model="form.email" type="email" :disabled="readonly" />
                </b-field>
                <b-field label="PPS No." horizontal>
                  <b-input v-model="form.govId" :disabled="readonly" />
                </b-field>
                <b-field label="Occupation" horizontal>
                  <b-input v-model="form.occupation" :disabled="readonly" />
                </b-field>
                <b-field label="Religion" horizontal>
                  <lookup-select-add v-model="form.religion" title="Religion" :lookupType="lookupTypes.Religion" :disabled="readonly" />
                </b-field>
                <b-field label="Nationality" horizontal>
                  <lookup-select-add
                    v-model="form.nationality"
                    title="Nationality"
                    :lookupType="lookupTypes.Nationality"
                    :disabled="readonly"
                  />
                </b-field>
                <b-field label="Ethnicity" horizontal class="field-nested">
                  <lookup-select-add v-model="form.ethnicity" title="Ethnicity" :lookupType="lookupTypes.Ethnicity" :disabled="readonly" />
                </b-field>
              </div>
            </div>
          </div>

          <div class="columns" v-if="showEnquiry && form.newEnquirer">
            <CollapseCard title="Enquiry Details" :open.sync="enquiryOpen">
              <DateSelector v-model="form.enquiryDate" :disabled="readonly" label="Enquiry Date" required :past="true" />

              <b-field label="How Received" horizontal>
                <EnumDropDown v-model="form.howReceived" :data="howReceivedSelect" required :disabled="readonly" />
              </b-field>

              <b-field label="Dependency Level" horizontal>
                <EnumDropDown v-model="form.dependencyLevel" :data="depLevelSelect" required :disabled="readonly" />
              </b-field>
              <b-field label="Health Status" horizontal>
                <EnumDropDown v-model="form.healthStatus" :data="healthStatusSelect" required :disabled="readonly" />
              </b-field>
              <NotesField v-model="form.enquiryNotes" :disabled="readonly" />
            </CollapseCard>
            <CollapseCard title="Person Enquirying" :open.sync="enquirerOpen" :showEdit="true" @edit="editEnquirer">
              <b-field label="First Name" horizontal>
                <b-input v-model="form.newEnquirer.firstName" disabled />
              </b-field>
              <b-field label="Last Name" horizontal>
                <b-input v-model="form.newEnquirer.lastName" disabled />
              </b-field>
              <PhoneEdit
                label="Mobile Phone"
                :phoneNumber="form.newEnquirer.mobileNumber"
                :countryCode="form.newEnquirer.mobileCountry"
                class="field"
                ref="newEnquirerMobileNumber"
                disabled
              />
              <b-field label="Relationship" horizontal class="field-nested">
                <b-input v-model="form.newEnquirer.relationship" disabled />
              </b-field>
            </CollapseCard>
          </div>

          <div class="columns">
            <CollapseCard title="Address" :open.sync="addressOpen">
              <address-edit v-model="form" class="field" :disabled="readonly" />
            </CollapseCard>

            <CollapseCard title="Phone Numbers" :open.sync="phonesOpen">
              <phone-edit
                label="Home Phone"
                :phoneNumber="form.homeNumber"
                :countryCode="form.homeCountry"
                @updatePhone="updateHomePhone"
                class="field"
                ref="homeNumber"
                :disabled="readonly"
              />
              <phone-edit
                label="Mobile Phone"
                :phoneNumber="form.mobileNumber"
                :countryCode="form.mobileCountry"
                @updatePhone="updateMobilePhone"
                class="field"
                ref="mobileNumber"
                :disabled="readonly"
              />
              <phone-edit
                label="Work Phone"
                :phoneNumber="form.workNumber"
                :countryCode="form.workCountry"
                @updatePhone="updateWorkPhone"
                class="field"
                ref="workNumber"
                :disabled="readonly"
              />
            </CollapseCard>
          </div>

          <div class="columns">
            <CollapseCard name="doctor" title="Doctor / Medical" :open.sync="doctorOpen">
              <gp-select :doctorId="form.doctorId" @select="updateGp" :disabled="readonly" />
              <PharmacySelect :pharmacyId="form.pharmacyId" @select="updatePharmacy" :disabled="readonly" />
              <b-field label="Medical Card" horizontal class="label-medium">
                <b-input v-model="form.medicalCardNum" autocomplete="medical" placeholder="Id" :disabled="readonly" />
                <DateSelector v-model="form.medicalCardExpiry" placeholder="Expiry Date" :disabled="readonly" :future="true" />
              </b-field>
              <b-field label="GP Visit Card" horizontal class="label-medium">
                <b-input v-model="form.gpVisitCardNum" placeholder="Id" :disabled="readonly" />
                <DateSelector v-model="form.gpVisitCardExpiry" placeholder="Expiry Date" :disabled="readonly" :future="true" />
              </b-field>
              <b-field label="Blood Type" horizontal class="label-medium">
                <EnumDropDown v-model="form.bloodType" :data="bloodTypeLookup" :required="false" :disabled="readonly" />
              </b-field>
            </CollapseCard>

            <CollapseCard name="insurance" title="Medical Insurance / Finance" :open.sync="medicalOpen">
              <b-field label="Funding" horizontal>
                <LookupSelectById v-model="form.fundingId" :lookupType="lookupTypes.FundingType" :disabled="readonly" title="Funding" />
              </b-field>
              <b-field label="Has Medical Insurance" horizontal class="pt-0 label-long">
                <b-checkbox v-model="form.medInsurance.hasMedicalInsurance" :disabled="readonly" />
              </b-field>
              <template v-if="form.medInsurance.hasMedicalInsurance">
                <b-field label="Provider" horizontal>
                  <b-input v-model="form.medInsurance.provider" required :disabled="readonly" />
                </b-field>
                <b-field label="Number" horizontal>
                  <b-input v-model="form.medInsurance.number" required :disabled="readonly" />
                </b-field>
                <b-field label="Plan" horizontal>
                  <b-input v-model="form.medInsurance.plan" :disabled="readonly" />
                </b-field>
                <DateSelector
                  label="Expiry Date"
                  v-model="form.medInsurance.expiryDate"
                  placeholder="Click to select plan expiry date."
                  position="is-top-right"
                  required
                  :disabled="readonly"
                  :future="true"
                />
              </template>
            </CollapseCard>
          </div>
          <div class="columns">
            <CollapseCard title="Next of Kin / Contacts" name="Contact" :open.sync="nokOpen" :showAdd="!readonly" @add="addNok">
              <ContextTable
                :tableData="nextOfKin"
                sortField="order"
                v-model="selectedNok"
                :canEdit="!readonly"
                @contextDefaultClicked="editNok"
                @menu-clicked="editNok"
                :isLoading="isLoadingNok"
                smallNoData
              >
                <b-table-column label="Contact Order" field="order" sortable v-slot="props">
                  {{ props.row.orderDesc }}
                </b-table-column>
                <b-table-column label="First Name" field="firstName" sortable v-slot="props">
                  {{ props.row.firstName }}
                </b-table-column>
                <b-table-column label="Last Name" field="lastName" sortable v-slot="props">
                  {{ props.row.lastName }}
                </b-table-column>
                <b-table-column label="Relationship" field="relationship" sortable v-slot="props">
                  {{ props.row.relationship }}
                </b-table-column>
                <b-table-column label="Responsibility" field="responsibility" sortable v-slot="props">
                  {{ props.row.responsibility }}
                </b-table-column>
                <b-table-column label="Contactable" field="contactable" sortable v-slot="props">
                  {{ props.row.contactable }}
                </b-table-column>
              </ContextTable>
            </CollapseCard>
          </div>

          <div class="columns">
            <MedicalHistoryList
              :historyType="medicalHistoryEnum.Medical"
              :data="medicalHistory"
              :residentId="form.residentId"
              :readonly="readonly"
              :isLoading="isLoadingMedHist"
              @refresh="refreshMedHist"
            />
            <MedicalHistoryList
              :historyType="medicalHistoryEnum.Surgical"
              :data="surgicalHistory"
              :residentId="form.residentId"
              :readonly="readonly"
              :isLoading="isLoadingSurgHist"
              @refresh="refreshSurgHist"
            />
          </div>

          <div class="columns">
            <MedicalHistoryList
              :historyType="medicalHistoryEnum.Allergies"
              :data="allergies"
              :residentId="form.residentId"
              :readonly="readonly"
              :isLoading="isLoadingAllergies"
              @refresh="refreshAllergies"
            />

            <CollapseCard title="Advanced Care Plan" :open.sync="advancedOpen">
              <div class="columns adv-care-plan">
                <div class="column">
                  <b-field label="Advanced Care Plan in Place" horizontal class="label-long">
                    <b-checkbox v-model="form.advancedCarePlan" :disabled="readonly" />
                    <file-picker
                      v-if="form.advancedCarePlan && !readonly"
                      :id="form.residentId"
                      :imageType="imageTypeAdvCarePlan"
                      @new-image="newAdvCarePlan"
                    />
                    <b-navbar-item
                      v-if="form.advancedCarePlan && form.advancedCarePlanFile && !readonly"
                      :href="form.advancedCarePlanUrl"
                      target="_blank"
                    >
                      <b-icon v-if="form.advancedCarePlanFileName" class="is-large" icon="paperclip" />{{ form.advancedCarePlanFileName }}
                    </b-navbar-item>
                  </b-field>
                </div>
                <div class="column">
                  <b-field v-if="form.advancedCarePlan" label="Assisted Decision Maker" horizontal class="label-long">
                    <b-input v-model="form.assistedDecisionMaker" :disabled="readonly" />
                  </b-field>
                </div>
              </div>
            </CollapseCard>
          </div>

          <div class="columns">
            <AttachmentsCard v-if="this.id" :entityId="this.id" :attachmentType="attachmentType" :readonly="readonly" />
          </div>

          <XyeaFormSave :isSaving="isSaving" cancelLink="/residents" @submit="submit" :disabled="readonly" />
        </form>
      </card-component>
    </tiles>
  </section>
</template>

<script>
import Tiles from "@/components/Tiles";
import XyeaFormSave from "@/components/XyeaFormSave";
import CardComponent from "@/components/CardComponent";
import CollapseCard from "@/components/CollapseCard";
import ContextTable from "@/components/ContextTable";
import ImagePicker from "@/components/ImagePicker";
import FilePicker from "@/components/FilePicker";
import UserAvatar from "@/components/UserAvatar";
import AddressEdit from "@/components/address/AddressEdit";
import PhoneEdit from "@/components/profile/PhoneEdit";
import PhoneMixin from "@/mixins/phoneMixin";
import PersonTitle from "@/components/profile/PersonTitle";
import LookupSelectAdd from "@/components/LookupSelectAdd.vue";
import PersonGender from "@/components/profile/PersonGender.vue";
import SelectLocation from "@/components/SelectLocation.vue";
import NextOfKinDialog from "@/components/dialogs/NextOfKinDialog.vue";
import DateSelector from "../components/DateSelector";
import LookupSelectById from "@/components/LookupSelectById.vue";
import NotesField from "@/components/NotesField.vue";
import ResidentStatusUpdate from "@/components/profile/ResidentStatusUpdate.vue";
import CurrentBed from "@/components/profile/CurrentBed.vue";
import MedicalHistoryList from "@/components/profile/MedicalHistoryList.vue";

import ResidentStatus from "@/enums/residentStatus";
import ContactOrder from "@/enums/contactOrder";
import BloodType from "@/enums/bloodType";
import EnumDropDown from "@/components/EnumDropDown";
import Attachments from "@/enums/imageType";
import AttachmentsCard from "@/components/AttachmentsCard.vue";
import MedicalHistory from "@/enums/medicalHistoryType";
import PharmacySelect from "@/components/profile/PharmacySelect";

/* eslint-disable import/first */
const GpSelect = () => import(/* webpackChunkName: "GpSelect" */ "@/components/profile/GpSelect");

import alerts from "@/common/alerts";
import Dates from "@/common/dates";

import Gender from "@/enums/gender";
import { mapGetters } from "vuex";
import PermissionsEnum from "@/enums/permissions";
import CheckDirty from "@/mixins/checkDirty";
import ResidentEditMixin from "@/mixins/residentEditMixin";

export default {
  name: "ResidentForm",
  mixins: [CheckDirty, PhoneMixin, ResidentEditMixin],
  components: {
    UserAvatar,
    ImagePicker,
    FilePicker,
    CardComponent,
    CollapseCard,
    ContextTable,
    DateSelector,
    Tiles,
    AddressEdit,
    PhoneEdit,
    GpSelect,
    PharmacySelect,
    PersonTitle,
    LookupSelectAdd,
    PersonGender,
    SelectLocation,
    EnumDropDown,
    XyeaFormSave,
    AttachmentsCard,
    LookupSelectById,
    NotesField,
    ResidentStatusUpdate,
    CurrentBed,
    MedicalHistoryList
  },
  props: {
    id: { type: [String, Number], default: null }
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      form: this.getClearFormObject(),
      imageTypeResident: Attachments.Type.Resident,
      imageTypeAdvCarePlan: Attachments.Type.AdvCarePlan,
      avatarUrl: "", // Had problem using form.avatar as it didn't seem to update
      lookupTypes: LookupSelectAdd.Enum,
      bloodTypeLookup: BloodType.Lookup,
      statusSelect: ResidentStatus.Select,
      enquiryOpen: true,
      enquirerOpen: true,
      addressOpen: false,
      doctorOpen: false,
      medicalOpen: false,
      advancedOpen: false,

      selectedNok: {},
      isLoadingNok: false,
      nokOpen: false,
      nextOfKin: [],

      medicalHistoryEnum: MedicalHistory.Enum,
      medicalHistory: [],
      surgicalHistory: [],
      allergies: [],
      isLoadingMedHist: false,
      isLoadingSurgHist: false,
      isLoadingAllergies: false,

      attachmentType: Attachments.Type.ResidentAttachment,

      locationNameReadOnly: null
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "hasPermission"]),
    readonly() {
      return !this.hasPermission(PermissionsEnum.ResidentUpdate);
    },
    canEditLocation() {
      return !this.isProfileExists || this.isAdmin;
    },
    isProfileExists() {
      return !!this.form.residentId;
    },
    fullName() {
      return `${this.firstName} ${this.lastName}`;
    },
    formCardTitle() {
      if (this.isProfileExists) {
        return "Edit Resident";
      } else {
        return "New Resident";
      }
    },
    selectedGender() {
      return this.form.medicalGender;
    },
    showEnquiry() {
      return this.form.residentStatus === ResidentStatus.Enum.Enquiry || this.form.residentStatus === ResidentStatus.Enum.ShortList;
    }
  },
  watch: {
    id(newValue) {
      if (!newValue) {
        this.form = this.getClearFormObject();
      } else {
        this.getData();
      }
    },
    selectedGender(newValue, oldValue) {
      if (!this.form.gender || this.form.gender === Gender.Lookup[oldValue]) {
        this.form.gender = Gender.Lookup[newValue];
      }
    },
    nokOpen(open) {
      if (open && !this.nextOfKin.length) this.refreshNok();
    },
    // link some card components opeb/close together
    medicalOpen(open) {
      this.doctorOpen = open;
    },
    doctorOpen(open) {
      this.medicalOpen = open;
    },
    addressOpen(open) {
      this.phonesOpen = open;
    },
    phonesOpen(open) {
      this.addressOpen = open;
    },
    contactOrder(order) {
      return ContactOrder.Lookup[order].value;
    }
  },
  created() {
    this.getData();
  },
  methods: {
    mapServerData(data) {
      this.form = data; // map top level params
      this.medInsurance = data.medInsurance; // map top level params
      // convert dates
      this.form.dob = Dates.dateOrNull(data.dob); // dob is mandatory
      this.form.medicalCardExpiry = Dates.dateOrNull(data.medicalCardExpiry);
      this.form.gpVisitCardExpiry = Dates.dateOrNull(data.gpVisitCardExpiry);
      this.form.enquiryDate = Dates.dateOrNull(data.enquiryDate);
      if (data.currentStatusChange) {
        this.form.currentStatusChange.statusChangeDate = Dates.dateOrNull(data.currentStatusChange.statusChangeDate);
      }

      this.avatarUrl = data.avatarUrl;
      this.locationNameReadOnly = data.locationName;

      if (data.medInsurance) {
        this.medInsurance.expiryDate = Dates.dateOrNull(data.medInsurance.expiryDate);
      } else {
        this.form.medInsurance = this.getClearMedicalInsurance();
      }
      this.$store.dispatch("resident/setResident", data);
      this.setNotDirty();
    },
    newImage(newImage) {
      this.form.avatar = newImage.fullFileName;
      this.avatarUrl = newImage.url;
    },
    newAdvCarePlan(newImage) {
      this.form.advancedCarePlanFile = newImage.fullFileName;
      this.form.advancedCarePlanFileName = newImage.fileName;
      this.form.advancedCarePlanUrl = newImage.url;
    },

    updateGp(doctorId) {
      this.form.doctorId = doctorId;
    },
    updatePharmacy(pharmacyId) {
      this.form.pharmacyId = pharmacyId;
    },

    async getData() {
      if (this.$route.params.id) {
        this.isLoading = true;
        await this.$http
          .get("residents/" + this.$route.params.id)
          .then(r => {
            this.mapServerData(r.data);
          })
          .catch(e => {
            alerts.showErrorAlert(e, "Error loading Resident details");
          })
          .finally(() => (this.isLoading = false));

        // redirect if no permission
        if (!this.form.residentId) this.$router.push(this.$store.getters.defaultRoute);
      }
    },

    isFormValid() {
      return this.arePhonesValid();
    },

    async submit() {
      // Need to manual check and report html5 validity so that we can open the closed sections if there is an error
      // otherwise  @submit.prevent="submit" is enough
      if (!this.$refs.residentForm.checkValidity()) {
        this.enquiryOpen = true;
        this.addressOpen = true;
        this.doctorOpen = true;
        this.medicalOpen = true;
        this.advancedOpen = true;
        // need long enough delay to open all cards so we can set focus
        setTimeout(() => {
          this.$refs.residentForm.reportValidity();
        }, 300);
        return;
      }
      if (!this.isFormValid()) return;

      this.isSaving = true;

      // save form with Dates as Date only
      var data = this.getFormData();

      if (this.isProfileExists) {
        await this.$http
          .put("residents/" + this.$route.params.id, data)
          .then(r => {
            this.setNotDirty(true);
            this.$router.push({ name: "residents" });
            this.$buefy.snackbar.open({ message: "Resident updated", queue: false });

            // If this is currently selected resident, then update stored details
            if (this.$store.getters["resident/residentId"] === r.data.residentId) {
              this.$store.dispatch("resident/setResident", r.data);
            }
          })
          .catch(e => {
            alerts.showErrorAlert(e, "Error updating Resident details");
          });
      } else {
        await this.$http
          .post("residents/", this.form)
          .then(r => {
            this.setNotDirty(true);
            this.$router.push({ name: "residents" });
            this.$buefy.snackbar.open({ message: "New Resident added", queue: false });

            // Auto select this new resident
            this.$store.dispatch("resident/setResident", r.data);
          })
          .catch(e => {
            alerts.showErrorAlert(e, "Error adding Resident details");
          });
      }
      this.isSaving = false;
    },

    addNok() {
      this.selectedNok = {};
      this.editNok();
    },
    editNok() {
      this.nokOpen = true;
      this.$buefy.modal.open({
        parent: this,
        component: NextOfKinDialog,
        hasModalCard: true,
        props: { nok: this.selectedNok, residentId: this.form.residentId },
        events: { refresh: this.refreshNok }
      });
    },
    editEnquirer() {
      this.$buefy.modal.open({
        parent: this,
        component: NextOfKinDialog,
        hasModalCard: true,
        props: { nok: this.form.newEnquirer, residentId: this.form.residentId },
        events: {
          refresh: updated => {
            this.form.newEnquirer = updated;
            if (this.nokOpen) this.refreshNok();
          }
        }
      });
    },
    refreshNok() {
      this.isLoadingNok = true;
      this.$http
        .get("nextofkin/" + this.form.residentId)
        .then(r => (this.nextOfKin = r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Next of Kin Details"))
        .finally(() => (this.isLoadingNok = false));
    },

    refreshMedHist() {
      this.isLoadingMedHist = true;
      const params = { residentId: this.form.residentId, type: MedicalHistory.Enum.Medical };
      this.$http
        .get("medicalhistory/viewMedHistory", { params })
        .then(r => (this.medicalHistory = r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Medical History"))
        .finally(() => (this.isLoadingMedHist = false));
    },

    refreshSurgHist() {
      this.isLoadingSurgHist = true;
      const params = { residentId: this.form.residentId, type: MedicalHistory.Enum.Surgical };
      this.$http
        .get("medicalhistory/viewMedHistory", { params })
        .then(r => (this.surgicalHistory = r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Surgical History"))
        .finally(() => (this.isLoadingSurgHist = false));
    },
    refreshAllergies() {
      this.isLoadingAllergies = true;
      const params = { residentId: this.form.residentId, type: MedicalHistory.Enum.Allergies };
      this.$http
        .get("medicalhistory/viewMedHistory", { params })
        .then(r => (this.allergies = r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Allergies"))
        .finally(() => (this.isLoadingAllergies = false));
    }
  }
};
</script>

<style>
.adv-care-plan .navbar-item {
  padding: 0;
}
.adv-care-plan .b-checkbox {
  padding: 15px 0;
}
.adv-care-plan .field-label {
  padding: 14px 0;
}
.adv-care-plan .control {
  padding: 5px 0;
}
</style>
