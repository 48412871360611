<template>
  <CollapseCard :title="title" :open.sync="isOpen" :showEdit="!readonly" @edit="updateHist">
    <b-table :data="data" :columns="columns" :loading="isLoading" smallNoData />
  </CollapseCard>
</template>

<script>
import CollapseCard from "@/components/CollapseCard";
import MedicalHistoryDialog from "@/components/dialogs/MedicalHistoryDialog";
import MedicalHistory from "@/enums/medicalHistoryType";

export default {
  components: { CollapseCard },
  props: {
    historyType: { type: Number, required: true },
    residentId: { type: Number, required: true },
    assessmentId: { type: Number },
    readonly: { type: Boolean },
    isLoading: { type: Boolean },
    initOpen: { type: Boolean },
    useCustomSave: { type: Boolean }, // use custom save func, used for PreAssessment, as we may not yet have assessmentId
    data: { type: Array }, // data use for the list
    fullData: { type: Object } // full data for displaying in update dialog
  },
  data() {
    return {
      isOpen: this.initOpen
    };
  },
  computed: {
    columns() {
      switch (this.historyType) {
        case MedicalHistory.Enum.Surgical:
          return [
            { field: "category", label: "Category", sortable: true },
            { field: "name", label: "Surgery", sortable: true },
            { field: "year", label: "Year", sortable: true },
            { field: "notes", label: "Notes", sortable: true }
          ];

        case MedicalHistory.Enum.Allergies:
          return [
            { field: "category", label: "Category", sortable: true },
            { field: "name", label: "Allergy", sortable: true },
            { field: "year", label: "Since", sortable: true },
            { field: "notes", label: "Notes", sortable: true }
          ];

        case MedicalHistory.Enum.Medical:
        default:
          return [
            { field: "category", label: "Category", sortable: true },
            { field: "name", label: "Condition", sortable: true },
            { field: "year", label: "Year", sortable: true },
            { field: "notes", label: "Notes", sortable: true }
          ];
      }
    },
    title() {
      switch (this.historyType) {
        case MedicalHistory.Enum.Surgical:
          return "Surgical History";
        case MedicalHistory.Enum.Allergies:
          return "Allergies";
        case MedicalHistory.Enum.Medical:
        default:
          return "Medical History";
      }
    }
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(open) {
        if (open && !this.data.length) {
          // refresh on next Tick, so residentId gets chance to be set
          this.$nextTick(() => this.$emit("refresh"));
        }
      }
    },
    initOpen(v) {
      // parent control sets InitOpen after loading data
      if (v) this.isOpen = v;
    }
  },
  methods: {
    updateHist() {
      this.isOpen = true;
      this.$buefy.modal.open({
        parent: this,
        component: MedicalHistoryDialog,
        hasModalCard: true,
        props: {
          residentId: this.residentId,
          assessmentId: this.assessmentId,
          historyType: this.historyType,
          useCustomSave: this.useCustomSave,
          fullData: this.fullData
        },
        events: { refresh: () => this.$emit("refresh"), save: v => this.$emit("save", v) }
      });
    }
  }
};
</script>
