import ResidentStatus from "@/enums/residentStatus";
import HowReceived from "@/enums/howReceived";
import ServiceType from "@/enums/serviceType";
import EnquiryEnum from "@/enums/enquiry";
import Barthel from "@/enums/barthel";
import Dates from "@/common/dates";

export default {
  data() {
    return {
      howReceivedSelect: HowReceived.SelectLookup,
      serviceTypeSelect: ServiceType.SelectLookup,
      depLevelSelect: Barthel.SelectLookup,
      healthStatusSelect: EnquiryEnum.HealthStatusSelectLookup
    };
  },
  methods: {
    getClearFormObject(newEnquiry) {
      this.avatarUrl = null;
      this.clearPhonesErrors();
      this.nextOfKin = [];
      this.medicalHistory = [];

      this.$store.dispatch("resident/setResident", null);

      return {
        residentId: 0,
        title: null,
        firstName: null,
        middleName: null,
        lastName: null,
        knownAs: null,
        dob: null,
        organisation: null,
        address1: null,
        address2: null,
        city: null,
        county: null,
        country: null,
        postcode: null,
        avatar: null,
        homeNumber: null,
        homeCountry: null,
        mobileNumber: null,
        mobileCountry: null,
        workNumber: null,
        workCountry: null,
        gender: null,
        medicalGender: null,
        govId: null,
        email: null,
        medicalCardNum: null,
        occupation: null,
        religion: null,
        medInsurance: this.getClearMedicalInsurance(),
        doctorId: null,
        pharmacyId: null,
        locationId: null,
        residentStatus: ResidentStatus.Enum.Enquiry,
        serviceType: null,
        fundingId: null,
        enquiryDate: newEnquiry ? new Date() : null, // defalt to today for new Enquiry,
        dependencyLevel: null,
        healthStatus: null,
        enquiryNotes: null,
        // prettier-ignore
        newEnquirer: newEnquiry
          ? {
            firstName: null,
            lastName: null,
            mobileNumber: null,
            mobileCountry: null,
            relationship: null
          }
          : null,
        bedsInfo: []
      };
    },
    getClearMedicalInsurance() {
      return {
        hasMedicalInsurance: false,
        provider: null,
        number: null,
        plan: null,
        expiryDate: null
      };
    },
    getFormData() {
      var data = { ...this.form };

      data.medInsurance = { ...this.form.medInsurance };
      data.dob = Dates.formatYYYYMMDD(data.dob);
      data.medicalCardExpiry = Dates.formatYYYYMMDD(data.medicalCardExpiry);
      data.gpVisitCardExpiry = Dates.formatYYYYMMDD(data.gpVisitCardExpiry);
      data.medInsurance.expiryDate = Dates.formatYYYYMMDD(data.medInsurance.expiryDate);

      // Don't send BedInfo, or StatusChange
      delete data.bedsInfo; // remove bedsInfo from dirty check as readonly on this form
      delete data.residentStatus; // residentStatus is updated via status change dialog
      delete data.currentStatusChange;

      return data;
    },
    updateCurrentStatus(v) {
      this.form.currentStatusChange = v;
      this.form.currentStatusChange.statusChangeDate = Dates.dateOrNull(v.statusChangeDate);
      this.form.residentStatus = v.status;

      // update status can result in bed changes, so we fresh here to be sure
      this.refreshBedAssigments();
    },
    async refreshBedAssigments() {
      this.loadingBeds = true;
      await this.$http
        .get("bedAssignments/currentResident/" + this.form.residentId)
        .then(r => (this.form.bedsInfo = r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error loading beds"))
        .finally(() => (this.loadingBeds = false));
    }
  }
};
