import { GetSelectLookup } from "./common";

const HealthStatusEnum = {
  Dementia: 0,
  High: 1,
  Medium: 2,
  Low: 3,
  Independent: 4
};

const HealthStatusLookup = ["Dementia", "High Dependency", "Medium Dependency", "Low Dependency", "Independent"];

export default {
  HealthStatusEnum: HealthStatusEnum,
  HealthStatusSelectLookup: GetSelectLookup(HealthStatusEnum, HealthStatusLookup),
  HealthStatusLookup: HealthStatusLookup
};
