const BloodTypeEnum = {
  OPositive: 1,
  ONegative: 2,
  APositive: 3,
  ANegative: 4,
  BPositive: 5,
  BNegative: 6,
  ABPositive: 7,
  ABNegative: 8
};

export default {
  Enum: BloodTypeEnum,
  Lookup: [
    { id: BloodTypeEnum.OPositive, value: "O Positive" },
    { id: BloodTypeEnum.ONegative, value: "O Negative" },
    { id: BloodTypeEnum.APositive, value: "A Positive" },
    { id: BloodTypeEnum.ANegative, value: "A Negative" },
    { id: BloodTypeEnum.BPositive, value: "B Positive" },
    { id: BloodTypeEnum.BNegative, value: "B Negative" },
    { id: BloodTypeEnum.ABPositive, value: "AB Positive" },
    { id: BloodTypeEnum.ABNegative, value: "AB Negative" }
  ]
};
