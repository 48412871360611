<template>
  <b-field v-if="value || !disabled" :label="label" horizontal class="field-nested" :class="fieldClass">
    <SpeechToText
      v-if="showNotes || value"
      :value="value"
      @input="v => $emit('input', v)"
      :placeholder="placeholder"
      :ref="notesRef"
      :required="required"
      :disabled="disabled"
    />
    <a v-else-if="!disabled" @click.prevent="notesClicked">
      <b-icon class="field-label" icon="message" title="Add notes" />
    </a>
  </b-field>
</template>

<script>
import SpeechToText from "@/components/SpeechToText";

export default {
  props: {
    value: { type: String },
    label: { type: String, default: "Notes" },
    notesRef: { type: String, required: true },
    fieldClass: { type: String },
    disabled: { type: Boolean },
    required: { type: Boolean },
    placeholder: { type: String, default: "Enter notes or observations" }
  },
  components: { SpeechToText },
  data() {
    return {
      showNotes: false
    };
  },
  methods: {
    notesClicked() {
      this.showNotes = true;
      this.$nextTick(function () {
        this.$refs[this.notesRef].$refs.inputArea.focus();
      });
    }
  }
};
</script>
