import { render, staticRenderFns } from "./PersonGender.vue?vue&type=template&id=0ed24e66&"
import script from "./PersonGender.vue?vue&type=script&lang=js&"
export * from "./PersonGender.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports