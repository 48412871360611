<template>
  <b-field label="County" horizontal>
    <b-select
      v-if="showDropDown"
      placeholder="Select a county"
      :value="value"
      @input="v => $emit('input', v)"
      :required="required"
      :disabled="disabled"
    >
      <option v-for="option in counties" :value="option" :key="option">Co. {{ option }} </option>
    </b-select>
    <b-input v-else :value="value" @input="v => $emit('input', v)" :required="required" :disabled="disabled" />
  </b-field>
</template>

<script>
import countryInfo from "./country";

export default {
  computed: {
    counties() {
      var matchingCountry = countryInfo.countries.find(c => c.CountryCode === this.country);
      return matchingCountry?.counties ?? [];
    },
    showDropDown() {
      return this.counties.length > 0;
    }
  },
  props: {
    value: { validator: prop => typeof prop === "string" || prop === null || prop === undefined, required: true },
    country: { validator: prop => typeof prop === "string" || prop === null || prop === undefined, required: true },
    disabled: { type: Boolean },
    required: { type: Boolean, default: true }
  }
};
</script>
