<template>
  <b-field :message="message">
    <b-radio :value="value" @input="v => $emit('input', v)" name="gender" :native-value="genderEnum.Male" :disabled="disabled" required>
      {{ genderValues.Male }}
    </b-radio>
    <b-radio :value="value" @input="v => $emit('input', v)" name="gender" :native-value="genderEnum.Female" :disabled="disabled" required>
      {{ genderValues.Female }}
    </b-radio>
  </b-field>
</template>

<script>
import Gender from "@/enums/gender";

export default {
  props: ["value", "disabled", "message"],
  data() {
    return {
      genderEnum: Gender.Enum,
      genderValues: Gender.Values
    };
  }
};
</script>
