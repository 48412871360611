<template>
  <div>
    <b-field label="Address" horizontal>
      <b-input v-model="value.address1" :required="required" :disabled="disabled" />
    </b-field>
    <b-field horizontal>
      <b-input v-model="value.address2" :disabled="disabled" />
    </b-field>
    <b-field label="City" horizontal>
      <b-input v-model="value.city" :required="required" :disabled="disabled" />
    </b-field>
    <select-county v-model="value.county" :country="value.country" :disabled="disabled" :required="required" />
    <select-country v-model="value.country" :disabled="disabled" :required="required" />
    <b-field label="Post Code" horizontal>
      <b-input v-model="value.postCode" maxlength="10" :has-counter="false" :disabled="disabled" class="inputFixedWidth-2" />
    </b-field>
  </div>
</template>

<script>
import SelectCounty from "./SelectCounty";
import SelectCountry from "./SelectCountry";

export default {
  props: {
    value: { type: Object },
    disabled: { type: Boolean },
    required: { type: Boolean, default: true }
  },
  components: { SelectCounty, SelectCountry }
};
</script>
