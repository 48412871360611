<template>
  <!-- Selection option based on the lookup text value, and allows adding a new one -->
  <b-field :message="message">
    <b-autocomplete
      :name="title"
      :placeholder="'Select ' + this.title"
      v-model="name"
      :keepFirst="true"
      @blur="blur"
      :data="filteredDataArray"
      @select="v => $emit('input', v)"
      :clearable="!disabled"
      open-on-focus
      ref="autocomplete"
      autocomplete="off"
      :disabled="disabled"
    >
      <template v-slot:header>
        <a @click="showAdd">
          <span> Add new... </span>
        </a>
      </template>

      <template v-slot:empty>No results for {{ name }}</template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import lookups from "@/enums/lookups.js";
export default {
  Enum: lookups.Enum,
  props: {
    value: { validator: prop => typeof prop === "string" || prop === null || prop === undefined, required: true },
    lookupType: { type: Number, required: true },
    title: { type: String, required: true },
    message: { type: String, required: false },
    disabled: { type: Boolean }
  },
  data() {
    return {
      fetchingData: false,
      name: this.value
    };
  },
  computed: {
    dataArray() {
      var values = this.$store.getters["lookups/getValues"](this.lookupType);
      if ((!values || !values.length) && !this.fetchingData) {
        this.getData();
      }
      return values;
    },
    filteredDataArray() {
      const searchName = this.name ? this.name.toLowerCase() : null;
      if (!searchName) return this.dataArray;
      const filtered = this.dataArray.filter(option => {
        return option.toLowerCase().indexOf(searchName) >= 0;
      });
      // clear any selection if no match
      if (!filtered.length && this.$refs.autocomplete) this.$refs.autocomplete.setHovered(null);
      return filtered;
    }
  },
  watch: {
    value() {
      this.name = this.value;
    }
  },
  methods: {
    async getData() {
      this.fetchingData = true;
      await this.$store.dispatch("lookups/getLookups", { lookupType: this.lookupType });
      this.fetchingData = false;
    },
    blur() {
      // if we have entered something, but no match, click new
      if (this.name && !this.filteredDataArray.length) this.showAdd();
    },
    showAdd() {
      const refField = this.$refs.autocomplete;
      this.$buefy.dialog.prompt({
        message: "Add new " + this.title,
        inputAttrs: {
          maxlength: 20,
          value: this.name
        },
        confirmText: "Add",
        onConfirm: value => {
          this.$store.dispatch("lookups/addLookup", { name: value, lookupType: this.lookupType });
          refField.setSelected(value);
        },
        onCancel: () => {
          refField.setSelected("");
        }
      });
    }
  }
};
</script>
