<template>
  <b-field :label="label" horizontal :class="{ hiddenLabel: !label }">
    <b-datepicker
      :value="value"
      @input="v => $emit('input', v)"
      :placeholder="placeholder"
      icon="calendar-today"
      trap-focus
      :min-date="minDate"
      :max-date="maxDate"
      :required="required"
      editable
      locale="en-GB"
      :disabled="disabled"
      :position="position"
      :class="customPosition"
    />
  </b-field>
</template>

<script>
import dates from "@/common/dates.js";
export default {
  props: {
    value: { type: Date },
    label: { type: String },
    placeholder: { type: String },
    disabled: { type: Boolean },
    required: { type: Boolean },
    position: { type: String },
    customPosition: { type: String },
    future: { type: Boolean },
    past: { type: Boolean }
  },
  computed: {
    minDate() {
      return this.future ? dates.today() : dates.MinDate();
    },
    maxDate() {
      return this.past ? dates.today() : null;
    }
  }
};
</script>
