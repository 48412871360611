<template>
  <b-field label="Bed" horizontal grouped>
    <b-field>
      <div v-for="(bed, i) in bedsInfo" :key="bed.bedAssignmentId">
        <div class="columns narrow is-mobile" :class="{ clickable: showIcons(i) }" @click="openBedDialog(bed, i)">
          <div class="column is-4">{{ bed.fullName }} {{ locationNameLookup(bed.locationId) }}</div>
          <div class="column is-4">
            From: {{ shortFormat(bed.fromDate) }}
            <span v-if="bed.toDate && showIcons(i)">Until: {{ shortFormat(bed.toDate) }}</span>
          </div>
          <!-- Edit / Delete only the last entry -->
          <div class="column is-4 columns is-mobile" v-if="showIcons(i)">
            <ClickIcon icon="edit" title="Edit Bed" @click="openBedDialog(bed, i)" />
            <ClickIcon icon="bed" title="Move Bed" @click="openBedDialog" />
            <DeleteObject
              :entity="isFutureDate(bed.fromDate) ? 'Future Bed Assignment' : 'Current Bed Assignment not Allowed'"
              :disabled="!isFutureDate(bed.fromDate)"
              deleteName="this bed assignment"
              :deleteId="bed.bedAssignmentId"
              url="bedAssignments"
              @deleted="refresh"
            />
          </div>
          <div class="column is-4" v-else>
            <span v-if="bed.toDate">Until: {{ shortFormat(bed.toDate) }}</span>
          </div>
        </div>
      </div>
      <div v-if="!bedsInfo.length && !disabled">
        <ClickIcon icon="bed" title="Assign new Bed" @click="openBedDialog" noPadding />
      </div>
    </b-field>
  </b-field>
</template>

<script>
import ClickIcon from "@/components/ClickIcon.vue";
import BedAssignmentDialog from "@/components/dialogs/BedAssignmentDialog.vue";
import DeleteObject from "@/components/DeleteObject.vue";
import DatesMixin from "@/mixins/datesMixin.js";

export default {
  props: {
    bedsInfo: { type: Array, default: () => [] },
    residentId: { type: Number },
    locationId: { type: Number },
    disabled: { type: Boolean }
  },
  mixins: [DatesMixin],
  components: { ClickIcon, DeleteObject },
  data() {
    return {};
  },
  methods: {
    locationNameLookup(locationId) {
      return this.$store.getters["locations/locationName"](locationId, null, false, " > ");
    },
    showIcons(i) {
      return !this.disabled && i === this.bedsInfo.length - 1;
    },
    refresh() {
      this.$emit("refresh");
    },
    openBedDialog(bedAssignment, i) {
      if (bedAssignment && !this.showIcons(i)) return;
      this.$buefy.modal.open({
        parent: this,
        component: BedAssignmentDialog,
        hasModalCard: true,
        props: { residentId: this.residentId, startLocationId: this.locationId, bedAssignment: bedAssignment },
        events: { added: this.refresh, updated: this.refresh }
      });
    }
  }
};
</script>
