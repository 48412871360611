import { GetSelectLookup } from "./common";

const RoomStatusEnum = {
  Normal: 0,
  Painted: 1,
  DeepCleaned: 2,
  Repairs: 3,
  NotAvailable: 4,
  Deleted: 5
};

const RoomTypeEnum = {
  Single: 1,
  Twin: 2,
  Tripple: 3
};

const RoomTypeLookup = ["", "Single", "Twin", "Tripple"];

const RoomStatusLookUp = ["Normal", "Being Painted", "Being Deep Cleaned", "Repairs / Maintenance", "Not Available"];

const BedStatusEnum = {
  All: -1,
  Available: 0,
  Occupied: 1,
  BeingPrepared: 2,
  Maintenance: 3,
  NotAvailable: 4,
  Deleted: 5
};
const BedStatusLookUp = ["Available", "Occupied", "Being Prepared", "Repairs / Maintenance", "Not Available"];

export default {
  RoomStatusEnum: RoomStatusEnum,
  RoomStatusSelectLookup: GetSelectLookup(RoomStatusEnum, RoomStatusLookUp),
  RoomStatusLookup: RoomStatusLookUp,

  RoomTypeEnum: RoomTypeEnum,
  RoomTypeSelectLookup: GetSelectLookup(RoomTypeEnum, RoomTypeLookup),
  RoomTypeLookup: RoomTypeLookup,

  BedStatusEnum: BedStatusEnum,
  BedStatusSelectLookup: GetSelectLookup(BedStatusEnum, BedStatusLookUp),
  BedStatusLookup: BedStatusLookUp
};
