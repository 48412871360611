import { GetSelectLookup } from "./common";

const AdmitFromEnum = {
  CareHome: 1,
  Hospital: 2,
  Home: 3,
  Other: 4
};

const AdmitFromLookup = ["", "Care Home", "Hospital", "Home", "Other"];

export default {
  Enum: AdmitFromEnum,
  SelectLookup: GetSelectLookup(AdmitFromEnum, AdmitFromLookup),
  Lookup: AdmitFromLookup
};
