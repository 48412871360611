export default {
  data() {
    return {
      phonesOpen: false,
      homeIsError: false,
      mobileIsError: false,
      workIsError: false
    };
  },
  methods: {
    updateHomePhone(phoneInfo) {
      this.form.homeNumber = phoneInfo.phoneNumber ?? null;
      this.form.homeCountry = phoneInfo.countryCode;
      this.homeIsError = phoneInfo.isError;
    },
    updateMobilePhone(phoneInfo) {
      this.form.mobileNumber = phoneInfo.phoneNumber ?? null;
      this.form.mobileCountry = phoneInfo.countryCode;
      this.mobileIsError = phoneInfo.isError;
    },
    updateWorkPhone(phoneInfo) {
      this.form.workNumber = phoneInfo.phoneNumber ?? null;
      this.form.workCountry = phoneInfo.countryCode;
      this.workIsError = phoneInfo.isError;
    },
    clearPhonesErrors() {
      this.homeIsError = false;
      this.mobileIsError = false;
      this.workIsError = false;
    },
    arePhonesValid() {
      if (this.homeIsError) {
        this.phonesOpen = true;
        this.scrollTo("homeNumber");
        return false;
      }
      if (this.mobileIsError) {
        this.phonesOpen = true;
        this.scrollTo("mobileNumber");
        return false;
      }
      if (this.workIsError) {
        this.phonesOpen = true;
        this.scrollTo("workNumber");
        return false;
      }
      return true;
    },

    scrollTo(ref) {
      setTimeout(() => {
        this.$refs[ref].$el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }, 200);
    }
  }
};
