<template>
  <div class="modalMinHeight">
    <XyeaFormEdit
      icon="bed"
      url="bedassignments/"
      entityName="Bed Assignment"
      :form="form"
      :formPostData="getFormPostData"
      :id="form.bedAssignmentId"
      @close="$emit('close')"
      @add="v => $emit('added', v)"
      @update="v => $emit('updated', v)"
      :dialog="true"
      :loadOnOpen="false"
    >
      <SelectLocation v-if="!isEdit" v-model="locationId" required :startLocationId="startLocationId" />
      <b-field label="From" horizontal>
        <DateSelector v-model="form.fromDate" required :disabled="!canUpdateFromDate" customPosition="is-over1" :future="!isAdmin" />
      </b-field>
      <b-field label="Until" horizontal>
        <DateSelector v-model="form.toDate" :disabled="!canUpdateToDate" customPosition="is-over2" :future="!isAdmin" />
      </b-field>

      <b-field horizontal class="hiddenFieldLabel">
        <b-input v-model="form.bedId" class="hiddenField" required />
      </b-field>
      <b-field v-if="isEdit" label="Bed" horizontal>
        <p class="unitLabel">{{ form.fullName }} {{ locationNameLookup(form.locationId) }}</p>
      </b-field>
      <b-field v-else label="Available Beds" horizontal>
        <b-autocomplete
          name="selectBed"
          v-model="bedname"
          field="fullName"
          ref="autocomplete"
          :data="filteredList"
          placeholder="Search / Select Bed"
          @select="selectBed"
          clearable
          open-on-focus
          :loading="isLoadingBeds"
          required
          v-focus
        >
          <template v-slot="props">
            <div class="columns">
              <div class="column">{{ props.option.fullName }}</div>
              <div class="column">{{ props.option.locationName }}</div>
              <div class="column">{{ bedsEnum.RoomTypeLookup[props.option.roomType] }}</div>
            </div>
          </template>
          <template v-slot:empty> {{ bedname ? "No Results for " + bedname : "No Available beds for these dates and location" }}</template>
        </b-autocomplete>
      </b-field>
    </XyeaFormEdit>
  </div>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import DateSelector from "@/components/DateSelector.vue";
import SelectLocation from "@/components/SelectLocation.vue";
import { mapGetters } from "vuex";

import BedsEnum from "@/enums/beds";
import dates from "@/common/dates.js";

export default {
  components: { XyeaFormEdit, DateSelector, SelectLocation },
  props: {
    bedAssignment: { type: Object },
    residentId: { type: Number },
    startLocationId: { type: Number }
  },

  data() {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      bedname: "",
      selectedBed: null,
      availableBeds: [],
      isLoadingBeds: false,
      bedsEnum: BedsEnum,
      locationId: this.startLocationId
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    filteredList() {
      const searchName = this.bedname ? this.bedname.toLowerCase() : null;
      if (!searchName) return this.availableBeds;
      const filtered = this.availableBeds.filter(option => {
        return option.fullName.toLowerCase().indexOf(searchName) >= 0;
      });
      // clear any selection if no match
      if (!filtered.length) this.$refs.autocomplete.setHovered(null);

      return filtered;
    },
    isEdit() {
      return !!this.form.bedAssignmentId;
    },
    canUpdateFromDate() {
      return !this.edit || this.form.fromDate >= dates.today();
    },
    canUpdateToDate() {
      return true;
    }
  },
  watch: {
    "form.fromDate": {
      handler() {
        this.getAvailableBeds();
      }
    },
    "form.toDate": {
      handler() {
        this.getAvailableBeds();
      }
    },
    locationId() {
      this.getAvailableBeds();
    }
  },
  methods: {
    locationNameLookup(locationId) {
      return this.$store.getters["locations/locationName"](locationId, null, true, " > ");
    },
    getClearFormObject() {
      return {
        bedAssignmentId: 0,
        bedId: null,
        fromDate: dates.today(),
        toDate: null
      };
    },
    getFormPostData() {
      // return new form object with Dates only
      return {
        bedAssignmentId: this.form.bedAssignmentId,
        bedId: this.form.bedId,
        residentId: this.residentId,
        fromDate: dates.formatYYYYMMDD(this.form.fromDate),
        toDate: dates.formatYYYYMMDD(this.form.toDate)
      };
    },
    selectBed(bed) {
      this.bedname = bed ? bed.name : "";
      this.form.bedId = bed?.bedId;
    },
    getAvailableBeds() {
      this.isLoadingBeds = true;
      const search = {
        page: -1,
        bedStatus: BedsEnum.BedStatusEnum.Available,
        locationId: this.locationId,
        fromDate: dates.formatYYYYMMDD(this.form.fromDate),
        toDate: dates.formatYYYYMMDD(this.form.toDate)
      };

      this.$http
        .get("rooms", { params: search })
        .then(r => (this.availableBeds = r.data.tableData))
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Beds"))
        .finally(() => (this.isLoadingBeds = false));
    }
  },
  mounted() {
    if (this.bedAssignment?.bedAssignmentId) {
      this.form = { ...this.bedAssignment };
      this.form.fromDate = dates.dateOrNull(this.bedAssignment.fromDate);
      this.form.toDate = dates.dateOrNull(this.bedAssignment.toDate);
    }
    this.getAvailableBeds();
  }
};
</script>

<style scoped>
.b1 {
  min-width: 50;
}
.b2 {
  min-width: 50;
}
.b3 {
  min-width: 50;
}
</style>
