<template>
  <b-button class="ml-3" @click="$emit('click')" type="is-primary" size="is-small" :icon-left="icon">{{ label }}</b-button>
</template>

<script>
export default {
  props: {
    label: { type: String, required: true },
    icon: { type: String }
  }
};
</script>
