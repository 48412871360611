import CurrencyEnum from "../../enums/currency";

export default {
  countries: [
    {
      Country: "Ireland",
      CountryCode: "IE",
      CountryCode3: "IRL",
      PhoneCode: "353",
      IsoCode: "372",
      AllowRestricted: true,
      Currency: CurrencyEnum.EUR,
      counties: [
        "Antrim",
        "Armagh",
        "Carlow",
        "Cavan",
        "Clare",
        "Cork",
        "Derry",
        "Donegal",
        "Down",
        "Dublin",
        "Galway",
        "Fermanagh",
        "Kerry",
        "Kildare",
        "Kilkenny",
        "Laois",
        "Limerick",
        "Leitrim",
        "Longford",
        "Louth",
        "Meath",
        "Mayo",
        "Monaghan",
        "Offaly",
        "Roscommon",
        "Sligo",
        "Tipperary",
        "Tyrone",
        "Waterford",
        "Wexford",
        "Westmeath",
        "Wicklow"
      ]
    },
    {
      Country: "United Kingdom",

      CountryCode: "GB",
      CountryCode3: "GBR",
      PhoneCode: "44",
      IsoCode: "826",
      AllowRestricted: true,
      Currency: CurrencyEnum.GBP,
      Alternatives: ["Great Britain", "U.K."]
    },

    { Country: "Afghanistan", CountryCode: "AF", CountryCode3: "AFG", PhoneCode: "93", IsoCode: "004" },
    { Country: "Albania", CountryCode: "AL", CountryCode3: "ALB", PhoneCode: "355", IsoCode: "008" },
    { Country: "Algeria", CountryCode: "DZ", CountryCode3: "DZA", PhoneCode: "213", IsoCode: "012" },
    { Country: "American Samoa", CountryCode: "AS", CountryCode3: "ASM", PhoneCode: "1 684", IsoCode: "016" },
    { Country: "Andorra", CountryCode: "AD", CountryCode3: "AND", PhoneCode: "376", IsoCode: "020" },
    { Country: "Angola", CountryCode: "AO", CountryCode3: "AGO", PhoneCode: "244", IsoCode: "024" },
    { Country: "Anguilla", CountryCode: "AI", CountryCode3: "AIA", PhoneCode: "1 264", IsoCode: "660" },
    { Country: "Antarctica", CountryCode: "AQ", CountryCode3: "ATA", PhoneCode: "672", IsoCode: "010" },
    {
      Country: "Antigua and Barbuda",

      CountryCode: "AG",
      CountryCode3: "ATG",
      PhoneCode: "1 268",
      IsoCode: "028"
    },
    { Country: "Argentina", CountryCode: "AR", CountryCode3: "ARG", PhoneCode: "54", IsoCode: "032" },
    { Country: "Armenia", CountryCode: "AM", CountryCode3: "ARM", PhoneCode: "374", IsoCode: "051" },
    { Country: "Aruba", CountryCode: "AW", CountryCode3: "ABW", PhoneCode: "297", IsoCode: "533" },
    {
      Country: "Australia",

      CountryCode: "AU",
      CountryCode3: "AUS",
      PhoneCode: "61",
      IsoCode: "036",
      AllowRestricted: false
    },
    {
      Country: "Austria",

      CountryCode: "AT",
      CountryCode3: "AUT",
      PhoneCode: "43",
      IsoCode: "040",
      AllowRestricted: true
    },
    { Country: "Azerbaijan", CountryCode: "AZ", CountryCode3: "AZE", PhoneCode: "994", IsoCode: "031" },
    { Country: "Bahamas", CountryCode: "BS", CountryCode3: "BHS", PhoneCode: "1 242", IsoCode: "044" },
    { Country: "Bahrain", CountryCode: "BH", CountryCode3: "BHR", PhoneCode: "973", IsoCode: "048" },
    { Country: "Bangladesh", CountryCode: "BD", CountryCode3: "BGD", PhoneCode: "880", IsoCode: "050" },
    { Country: "Barbados", CountryCode: "BB", CountryCode3: "BRB", PhoneCode: "1 246", IsoCode: "052" },
    { Country: "Belarus", CountryCode: "BY", CountryCode3: "BLR", PhoneCode: "375", IsoCode: "112" },
    {
      Country: "Belgium",

      CountryCode: "BE",
      CountryCode3: "BEL",
      PhoneCode: "32",
      IsoCode: "056",
      AllowRestricted: true
    },
    { Country: "Belize", CountryCode: "BZ", CountryCode3: "BLZ", PhoneCode: "501", IsoCode: "084" },
    { Country: "Benin", CountryCode: "BJ", CountryCode3: "BEN", PhoneCode: "229", IsoCode: "204" },
    { Country: "Bermuda", CountryCode: "BM", CountryCode3: "BMU", PhoneCode: "1 441", IsoCode: "060" },
    { Country: "Bhutan", CountryCode: "BT", CountryCode3: "BTN", PhoneCode: "975", IsoCode: "064" },
    { Country: "Bolivia", CountryCode: "BO", CountryCode3: "BOL", PhoneCode: "591", IsoCode: "068" },
    {
      Country: "Bosnia and Herzegovina",

      CountryCode: "BA",
      CountryCode3: "BIH",
      PhoneCode: "387",
      IsoCode: "070"
    },
    { Country: "Botswana", CountryCode: "BW", CountryCode3: "BWA", PhoneCode: "267", IsoCode: "072" },
    { Country: "Brazil", CountryCode: "BR", CountryCode3: "BWA", PhoneCode: "55", IsoCode: "076" },
    {
      Country: "British Indian Ocean Territory",

      CountryCode: "IO",
      CountryCode3: "IOT",
      PhoneCode: "",
      IsoCode: "086"
    },
    {
      Country: "British Virgin Islands",

      CountryCode: "VG",
      CountryCode3: "VGB",
      PhoneCode: "1 284",
      IsoCode: "092"
    },
    { Country: "Brunei", CountryCode: "BN", CountryCode3: "BRN", PhoneCode: "673", IsoCode: "096" },
    {
      Country: "Bulgaria",

      CountryCode: "BG",
      CountryCode3: "BGR",
      PhoneCode: "359",
      IsoCode: "100",
      AllowRestricted: true
    },
    { Country: "Burkina Faso", CountryCode: "BF", CountryCode3: "BFA", PhoneCode: "226", IsoCode: "854" },
    { Country: "Burma (Myanmar)", CountryCode: "MM", CountryCode3: "MMR", PhoneCode: "95", IsoCode: "104" },
    { Country: "Burundi", CountryCode: "BI", CountryCode3: "BDI", PhoneCode: "257", IsoCode: "108" },
    { Country: "Cambodia", CountryCode: "KH", CountryCode3: "KHM", PhoneCode: "855", IsoCode: "116" },
    { Country: "Cameroon", CountryCode: "CM", CountryCode3: "CMR", PhoneCode: "237", IsoCode: "120" },
    {
      Country: "Canada",

      CountryCode: "CA",
      CountryCode3: "CAN",
      PhoneCode: "1",
      IsoCode: "124",
      AllowRestricted: false
    },
    { Country: "Cape Verde", CountryCode: "CV", CountryCode3: "CPV", PhoneCode: "238", IsoCode: "132" },
    { Country: "Cayman Islands", CountryCode: "KY", CountryCode3: "CYM", PhoneCode: "1 345", IsoCode: "136" },
    {
      Country: "Central African Republic",

      CountryCode: "CF",
      CountryCode3: "CAF",
      PhoneCode: "236",
      IsoCode: "140"
    },
    { Country: "Chad", CountryCode: "TD", CountryCode3: "TCD", PhoneCode: "235", IsoCode: "148" },
    { Country: "Chile", CountryCode: "CL", CountryCode3: "CHL", PhoneCode: "56", IsoCode: "152" },
    { Country: "China", CountryCode: "CN", CountryCode3: "CHN", PhoneCode: "86", IsoCode: "156" },
    {
      Country: "Christmas Island",

      CountryCode: "CX",
      CountryCode3: "CXR",
      PhoneCode: "61",
      IsoCode: "162"
    },
    {
      Country: "Cocos (Keeling) Islands",

      CountryCode: "CC",
      CountryCode3: "CCK",
      PhoneCode: "61",
      IsoCode: "166"
    },
    { Country: "Colombia", CountryCode: "CO", CountryCode3: "COL", PhoneCode: "57", IsoCode: "170" },
    { Country: "Comoros", CountryCode: "KM", CountryCode3: "COM", PhoneCode: "269", IsoCode: "174" },
    { Country: "Cook Islands", CountryCode: "CK", CountryCode3: "COK", PhoneCode: "682", IsoCode: "184" },
    { Country: "Costa Rica", CountryCode: "CR", CountryCode3: "CRI", PhoneCode: "506", IsoCode: "188" },
    {
      Country: "Croatia",

      CountryCode: "HR",
      CountryCode3: "HRV",
      PhoneCode: "385",
      IsoCode: "191",
      AllowRestricted: true
    },
    { Country: "Cuba", CountryCode: "CU", CountryCode3: "CUB", PhoneCode: "53", IsoCode: "192" },
    {
      Country: "Cyprus",

      CountryCode: "CY",
      CountryCode3: "CYP",
      PhoneCode: "357",
      IsoCode: "196",
      AllowRestricted: true
    },
    {
      Country: "Czech Republic",

      CountryCode: "CZ",
      CountryCode3: "CZE",
      PhoneCode: "420",
      IsoCode: "203",
      AllowRestricted: true
    },
    {
      Country: "Democratic Republic of the Congo",

      CountryCode: "CD",
      CountryCode3: "COD",
      PhoneCode: "243",
      IsoCode: "180"
    },
    {
      Country: "Denmark",

      CountryCode: "DK",
      CountryCode3: "DNK",
      PhoneCode: "45",
      IsoCode: "208",
      AllowRestricted: true
    },
    { Country: "Djibouti", CountryCode: "DJ", CountryCode3: "DJI", PhoneCode: "253", IsoCode: "262" },
    { Country: "Dominica", CountryCode: "DM", CountryCode3: "DMA", PhoneCode: "1 767", IsoCode: "212" },
    {
      Country: "Dominican Republic",

      CountryCode: "DO",
      CountryCode3: "DOM",
      PhoneCode: "1 809",
      IsoCode: "214"
    },
    { Country: "Ecuador", CountryCode: "EC", CountryCode3: "ECU", PhoneCode: "593", IsoCode: "218" },
    { Country: "Egypt", CountryCode: "EG", CountryCode3: "EGY", PhoneCode: "20", IsoCode: "818" },
    { Country: "El Salvador", CountryCode: "SV", CountryCode3: "SLV", PhoneCode: "503", IsoCode: "222" },
    {
      Country: "Equatorial Guinea",

      CountryCode: "GQ",
      CountryCode3: "GNQ",
      PhoneCode: "240",
      IsoCode: "226"
    },
    { Country: "Eritrea", CountryCode: "ER", CountryCode3: "ERI", PhoneCode: "291", IsoCode: "232" },
    {
      Country: "Estonia",

      CountryCode: "EE",
      CountryCode3: "EST",
      PhoneCode: "372",
      IsoCode: "233",
      AllowRestricted: true
    },
    { Country: "Ethiopia", CountryCode: "ET", CountryCode3: "ETH", PhoneCode: "251", IsoCode: "231" },
    {
      Country: "Falkland Islands",

      CountryCode: "FK",
      CountryCode3: "FLK",
      PhoneCode: "500",
      IsoCode: "238"
    },
    { Country: "Faroe Islands", CountryCode: "FO", CountryCode3: "FRO", PhoneCode: "298", IsoCode: "234" },
    { Country: "Fiji", CountryCode: "FJ", CountryCode3: "FJI", PhoneCode: "679", IsoCode: "242" },
    {
      Country: "Finland",

      CountryCode: "FI",
      CountryCode3: "FIN",
      PhoneCode: "358",
      IsoCode: "246",
      AllowRestricted: true,
      Currency: CurrencyEnum.EUR
    },
    {
      Country: "France",

      CountryCode: "FR",
      CountryCode3: "FRA",
      PhoneCode: "33",
      IsoCode: "250",
      AllowRestricted: true,
      Currency: CurrencyEnum.EUR
    },
    {
      Country: "French Polynesia",

      CountryCode: "PF",
      CountryCode3: "PYF",
      PhoneCode: "689",
      IsoCode: "258"
    },
    { Country: "Gabon", CountryCode: "GA", CountryCode3: "GAB", PhoneCode: "241", IsoCode: "266" },
    { Country: "Gambia", CountryCode: "GM", CountryCode3: "GMB", PhoneCode: "220", IsoCode: "270" },
    // {Country: "Gaza Strip", CountryCode: "", CountryCode3: "xxx", PhoneCode: "970", IsoCode: "004"},
    { Country: "Georgia", CountryCode: "GE", CountryCode3: "GEO", PhoneCode: "995", IsoCode: "268" },
    {
      Country: "Germany",

      CountryCode: "DE",
      CountryCode3: "DEU",
      PhoneCode: "49",
      IsoCode: "276",
      AllowRestricted: true,
      Currency: CurrencyEnum.EUR
    },
    { Country: "Ghana", CountryCode: "GH", CountryCode3: "GHA", PhoneCode: "233", IsoCode: "288" },
    { Country: "Gibraltar", CountryCode: "GI", CountryCode3: "GIB", PhoneCode: "350", IsoCode: "292" },
    {
      Country: "Greece",

      CountryCode: "GR",
      CountryCode3: "GRC",
      PhoneCode: "30",
      IsoCode: "300",
      AllowRestricted: true,
      Currency: CurrencyEnum.EUR
    },
    { Country: "Greenland", CountryCode: "GL", CountryCode3: "GRL", PhoneCode: "299", IsoCode: "304" },
    { Country: "Grenada", CountryCode: "GD", CountryCode3: "GRD", PhoneCode: "1 473", IsoCode: "308" },
    { Country: "Guam", CountryCode: "GU", CountryCode3: "GUM", PhoneCode: "1 671", IsoCode: "316" },
    { Country: "Guatemala", CountryCode: "GT", CountryCode3: "GTM", PhoneCode: "502", IsoCode: "320" },
    {
      Country: "Guernsey",

      CountryCode: "GG",
      CountryCode3: "GGY",
      PhoneCode: "44",
      IsoCode: "831",
      AllowRestricted: false
    },
    { Country: "Guinea", CountryCode: "GN", CountryCode3: "GIN", PhoneCode: "224", IsoCode: "324" },
    { Country: "Guinea-Bissau", CountryCode: "GW", CountryCode3: "GNB", PhoneCode: "245", IsoCode: "624" },
    { Country: "Guyana", CountryCode: "GY", CountryCode3: "GUY", PhoneCode: "592", IsoCode: "328" },
    { Country: "Haiti", CountryCode: "HT", CountryCode3: "HTI", PhoneCode: "509", IsoCode: "332" },
    {
      Country: "Holy See (Vatican City)",

      CountryCode: "VA",
      CountryCode3: "VAT",
      PhoneCode: "39",
      IsoCode: "336"
    },
    { Country: "Honduras", CountryCode: "HN", CountryCode3: "HND", PhoneCode: "504", IsoCode: "340" },
    // {Country: "Hong Kong", CountryCode: "HK", CountryCode3: "xxx", PhoneCode: "852", IsoCode: "004"},
    {
      Country: "Hungary",

      CountryCode: "HU",
      CountryCode3: "HUN",
      PhoneCode: "36",
      IsoCode: "348",
      AllowRestricted: true
    },
    {
      Country: "Iceland",

      CountryCode: "IS",
      CountryCode3: "ISL",
      PhoneCode: "354",
      IsoCode: "352",
      AllowRestricted: true
    },
    { Country: "India", CountryCode: "IN", CountryCode3: "IND", PhoneCode: "91", IsoCode: "356" },
    { Country: "Indonesia", CountryCode: "ID", CountryCode3: "IDN", PhoneCode: "62", IsoCode: "360" },
    { Country: "Iran", CountryCode: "IR", CountryCode3: "IRN", PhoneCode: "98", IsoCode: "364" },
    { Country: "Iraq", CountryCode: "IQ", CountryCode3: "IRQ", PhoneCode: "964", IsoCode: "368" },
    {
      Country: "Isle of Man",
      CountryCode: "IM",
      CountryCode3: "IMN",
      PhoneCode: "44",
      IsoCode: "833",
      AllowRestricted: false
    },
    { Country: "Israel", CountryCode: "IL", CountryCode3: "ISR", PhoneCode: "972", IsoCode: "376" },
    {
      Country: "Italy",

      CountryCode: "IT",
      CountryCode3: "ITA",
      PhoneCode: "39",
      IsoCode: "380",
      AllowRestricted: true,
      Currency: CurrencyEnum.EUR
    },
    { Country: "Ivory Coast", CountryCode: "CI", CountryCode3: "CIV", PhoneCode: "225", IsoCode: "384" },
    { Country: "Jamaica", CountryCode: "JM", CountryCode3: "JAM", PhoneCode: "1 876", IsoCode: "388" },
    { Country: "Japan", CountryCode: "JP", CountryCode3: "JPN", PhoneCode: "81", IsoCode: "392" },
    {
      Country: "Jersey",

      CountryCode: "JE",
      CountryCode3: "JEY",
      PhoneCode: "44",
      IsoCode: "832",
      AllowRestricted: false
    },
    { Country: "Jordan", CountryCode: "JO", CountryCode3: "JOR", PhoneCode: "962", IsoCode: "400" },
    { Country: "Kazakhstan", CountryCode: "KZ", CountryCode3: "KAZ", PhoneCode: "7", IsoCode: "398" },
    { Country: "Kenya", CountryCode: "KE", CountryCode3: "KEN", PhoneCode: "254", IsoCode: "404" },
    { Country: "Kiribati", CountryCode: "KI", CountryCode3: "KIR", PhoneCode: "686", IsoCode: "296" },
    // {Country: "Kosovo", CountryCode: "/ ", CountryCode3: "xxx", PhoneCode: "381", IsoCode: "004"},
    { Country: "Kuwait", CountryCode: "KW", CountryCode3: "KWT", PhoneCode: "965", IsoCode: "414" },
    { Country: "Kyrgyzstan", CountryCode: "KG", CountryCode3: "KGZ", PhoneCode: "996", IsoCode: "417" },
    { Country: "Laos", CountryCode: "LA", CountryCode3: "LAO", PhoneCode: "856", IsoCode: "418" },
    {
      Country: "Latvia",

      CountryCode: "LV",
      CountryCode3: "LVA",
      PhoneCode: "371",
      IsoCode: "428",
      AllowRestricted: true
    },
    { Country: "Lebanon", CountryCode: "LB", CountryCode3: "LBN", PhoneCode: "961", IsoCode: "422" },
    { Country: "Lesotho", CountryCode: "LS", CountryCode3: "LSO", PhoneCode: "266", IsoCode: "426" },
    { Country: "Liberia", CountryCode: "LR", CountryCode3: "LBR", PhoneCode: "231", IsoCode: "430" },
    { Country: "Libya", CountryCode: "LY", CountryCode3: "LBY", PhoneCode: "218", IsoCode: "434" },
    {
      Country: "Liechtenstein",

      CountryCode: "LI",
      CountryCode3: "LIE",
      PhoneCode: "423",
      IsoCode: "438",
      AllowRestricted: true
    },
    {
      Country: "Lithuania",

      CountryCode: "LT",
      CountryCode3: "LVA",
      PhoneCode: "370",
      IsoCode: "440",
      AllowRestricted: true
    },
    {
      Country: "Luxembourg",

      CountryCode: "LU",
      CountryCode3: "LUX",
      PhoneCode: "352",
      IsoCode: "442",
      AllowRestricted: true
    },
    // {Country: "Macau", CountryCode: "MO", CountryCode3: "xxx", PhoneCode: "853", IsoCode: "004"},
    { Country: "Macedonia", CountryCode: "MK", CountryCode3: "MKD", PhoneCode: "389", IsoCode: "807" },
    { Country: "Madagascar", CountryCode: "MG", CountryCode3: "MDG", PhoneCode: "261", IsoCode: "450" },
    { Country: "Malawi", CountryCode: "MW", CountryCode3: "MWI", PhoneCode: "265", IsoCode: "454" },
    { Country: "Malaysia", CountryCode: "MY", CountryCode3: "MYS", PhoneCode: "60", IsoCode: "458" },
    { Country: "Maldives", CountryCode: "MV", CountryCode3: "MDV", PhoneCode: "960", IsoCode: "462" },
    { Country: "Mali", CountryCode: "ML", CountryCode3: "MLI", PhoneCode: "223", IsoCode: "466" },
    {
      Country: "Malta",

      CountryCode: "MT",
      CountryCode3: "MLT",
      PhoneCode: "356",
      IsoCode: "470",
      AllowRestricted: true
    },
    {
      Country: "Marshall Islands",

      CountryCode: "MH",
      CountryCode3: "MHL",
      PhoneCode: "692",
      IsoCode: "584"
    },
    // Martinique
    { Country: "Mauritania", CountryCode: "MR", CountryCode3: "MRT", PhoneCode: "222", IsoCode: "478" },
    { Country: "Mauritius", CountryCode: "MU", CountryCode3: "MUS", PhoneCode: "230", IsoCode: "480" },
    { Country: "Mayotte", CountryCode: "YT", CountryCode3: "MYT", PhoneCode: "262", IsoCode: "175" },
    { Country: "Mexico", CountryCode: "MX", CountryCode3: "MEX", PhoneCode: "52", IsoCode: "484" },
    { Country: "Micronesia", CountryCode: "FM", CountryCode3: "FSM", PhoneCode: "691", IsoCode: "583" },
    { Country: "Moldova", CountryCode: "MD", CountryCode3: "MDA", PhoneCode: "373", IsoCode: "498" },
    { Country: "Monaco", CountryCode: "MC", CountryCode3: "MCO", PhoneCode: "377", IsoCode: "492" },
    { Country: "Mongolia", CountryCode: "MN", CountryCode3: "MNG", PhoneCode: "976", IsoCode: "496" },
    { Country: "Montenegro", CountryCode: "ME", CountryCode3: "MNE", PhoneCode: "382", IsoCode: "499" },
    { Country: "Montserrat", CountryCode: "MS", CountryCode3: "MSR", PhoneCode: "1 664", IsoCode: "500" },
    { Country: "Morocco", CountryCode: "MA", CountryCode3: "MAR", PhoneCode: "212", IsoCode: "504" },
    { Country: "Mozambique", CountryCode: "MZ", CountryCode3: "MOZ", PhoneCode: "258", IsoCode: "508" },
    { Country: "Namibia", CountryCode: "NA", CountryCode3: "NAM", PhoneCode: "264", IsoCode: "516" },
    { Country: "Nauru", CountryCode: "NR", CountryCode3: "NRU", PhoneCode: "674", IsoCode: "520" },
    { Country: "Nepal", CountryCode: "NP", CountryCode3: "NPL", PhoneCode: "977", IsoCode: "524" },
    {
      Country: "Netherlands",

      CountryCode: "NL",
      CountryCode3: "NLD",
      PhoneCode: "31",
      IsoCode: "528",
      AllowRestricted: true
    },
    {
      Country: "Netherlands Antilles",

      CountryCode: "AN",
      CountryCode3: "ANT",
      PhoneCode: "599",
      IsoCode: "530"
    },
    { Country: "New Caledonia", CountryCode: "NC", CountryCode3: "NCL", PhoneCode: "687", IsoCode: "540" },
    {
      Country: "New Zealand",

      CountryCode: "NZ",
      CountryCode3: "NZL",
      PhoneCode: "64",
      IsoCode: "554",
      AllowRestricted: false
    },
    { Country: "Nicaragua", CountryCode: "NI", CountryCode3: "NIC", PhoneCode: "505", IsoCode: "558" },
    { Country: "Niger", CountryCode: "NE", CountryCode3: "NER", PhoneCode: "227", IsoCode: "562" },
    { Country: "Nigeria", CountryCode: "NG", CountryCode3: "NGA", PhoneCode: "234", IsoCode: "566" },
    { Country: "Niue", CountryCode: "NU", CountryCode3: "NIU", PhoneCode: "683", IsoCode: "570" },
    { Country: "Norfolk Island", CountryCode: "/ ", CountryCode3: "NFK", PhoneCode: "672", IsoCode: "574" },
    { Country: "North Korea", CountryCode: "KP", CountryCode3: "MNP", PhoneCode: "850", IsoCode: "580" },
    {
      Country: "Northern Mariana Islands",

      CountryCode: "MP",
      CountryCode3: "NOR",
      PhoneCode: "1 670",
      IsoCode: "578"
    },
    {
      Country: "Norway",

      CountryCode: "NO",
      CountryCode3: "NOR",
      PhoneCode: "47",
      IsoCode: "578",
      AllowRestricted: true
    },
    { Country: "Oman", CountryCode: "OM", CountryCode3: "PAK", PhoneCode: "968", IsoCode: "586" },
    { Country: "Pakistan", CountryCode: "PK", CountryCode3: "PLW", PhoneCode: "92", IsoCode: "585" },
    { Country: "Palau", CountryCode: "PW", CountryCode3: "PSE", PhoneCode: "680", IsoCode: "275" },
    { Country: "Panama", CountryCode: "PA", CountryCode3: "PAN", PhoneCode: "507", IsoCode: "591" },
    {
      Country: "Papua New Guinea",

      CountryCode: "PG",
      CountryCode3: "PNG",
      PhoneCode: "675",
      IsoCode: "598"
    },
    { Country: "Paraguay", CountryCode: "PY", CountryCode3: "PRY", PhoneCode: "595", IsoCode: "600" },
    { Country: "Peru", CountryCode: "PE", CountryCode3: "PER", PhoneCode: "51", IsoCode: "604" },
    { Country: "Philippines", CountryCode: "PH", CountryCode3: "PHL", PhoneCode: "63", IsoCode: "608" },
    {
      Country: "Pitcairn Islands",

      CountryCode: "PN",
      CountryCode3: "PCN",
      PhoneCode: "870",
      IsoCode: "612"
    },
    {
      Country: "Poland",

      CountryCode: "PL",
      CountryCode3: "POL",
      PhoneCode: "48",
      IsoCode: "616",
      AllowRestricted: true,
      Currency: CurrencyEnum.PLN
    },
    {
      Country: "Portugal",

      CountryCode: "PT",
      CountryCode3: "PRT",
      PhoneCode: "351",
      IsoCode: "620",
      AllowRestricted: true
    },
    { Country: "Puerto Rico", CountryCode: "PR", CountryCode3: "PRI", PhoneCode: "1", IsoCode: "630" },
    { Country: "Qatar", CountryCode: "QA", CountryCode3: "QAT", PhoneCode: "974", IsoCode: "634" },
    {
      Country: "Republic of the Congo",

      CountryCode: "CG",
      CountryCode3: "REU",
      PhoneCode: "242",
      IsoCode: "638"
    },
    {
      Country: "Romania",

      CountryCode: "RO",
      CountryCode3: "ROU",
      PhoneCode: "40",
      IsoCode: "642",
      AllowRestricted: true
    },
    { Country: "Russia", CountryCode: "RU", CountryCode3: "RUS", PhoneCode: "7", IsoCode: "643" },
    { Country: "Rwanda", CountryCode: "RW", CountryCode3: "RWA", PhoneCode: "250", IsoCode: "646" },
    {
      Country: "Saint Barthelemy",

      CountryCode: "BL",
      CountryCode3: "BLM",
      PhoneCode: "590",
      IsoCode: "652"
    },
    { Country: "Saint Helena", CountryCode: "SH", CountryCode3: "SHN", PhoneCode: "290", IsoCode: "654" },
    {
      Country: "Saint Kitts and Nevis",

      CountryCode: "KN",
      CountryCode3: "KNA",
      PhoneCode: "1 869",
      IsoCode: "659"
    },
    { Country: "Saint Lucia", CountryCode: "LC", CountryCode3: "LCA", PhoneCode: "1 758", IsoCode: "662" },
    { Country: "Saint Martin", CountryCode: "MF", CountryCode3: "MAF", PhoneCode: "1 599", IsoCode: "663" },
    {
      Country: "Saint Pierre and Miquelon",

      CountryCode: "PM",
      CountryCode3: "SPM",
      PhoneCode: "508",
      IsoCode: "666"
    },
    {
      Country: "Saint Vincent and the Grenadines",

      CountryCode: "VC",
      CountryCode3: "VCT",
      PhoneCode: "1 784",
      IsoCode: "670"
    },
    { Country: "Samoa", CountryCode: "WS", CountryCode3: "WSM", PhoneCode: "685", IsoCode: "882" },
    { Country: "San Marino", CountryCode: "SM", CountryCode3: "SMR", PhoneCode: "378", IsoCode: "674" },
    {
      Country: "Sao Tome and Principe",

      CountryCode: "ST",
      CountryCode3: "STP",
      PhoneCode: "239",
      IsoCode: "678"
    },
    { Country: "Saudi Arabia", CountryCode: "SA", CountryCode3: "SAU", PhoneCode: "966", IsoCode: "682" },
    { Country: "Senegal", CountryCode: "SN", CountryCode3: "SEN", PhoneCode: "221", IsoCode: "686" },
    { Country: "Serbia", CountryCode: "RS", CountryCode3: "SRB", PhoneCode: "381", IsoCode: "688" },
    { Country: "Seychelles", CountryCode: "SC", CountryCode3: "SYC", PhoneCode: "248", IsoCode: "690" },
    { Country: "Sierra Leone", CountryCode: "SL", CountryCode3: "SLE", PhoneCode: "232", IsoCode: "694" },
    { Country: "Singapore", CountryCode: "SG", CountryCode3: "SGP", PhoneCode: "65", IsoCode: "702" },
    {
      Country: "Slovakia",

      CountryCode: "SK",
      CountryCode3: "SVK",
      PhoneCode: "421",
      IsoCode: "703",
      AllowRestricted: true
    },
    {
      Country: "Slovenia",

      CountryCode: "SI",
      CountryCode3: "SVN",
      PhoneCode: "386",
      IsoCode: "705",
      AllowRestricted: true
    },
    { Country: "Solomon Islands", CountryCode: "SB", CountryCode3: "SLB", PhoneCode: "677", IsoCode: "90" },
    { Country: "Somalia", CountryCode: "SO", CountryCode3: "SOM", PhoneCode: "252", IsoCode: "706" },
    { Country: "South Africa", CountryCode: "ZA", CountryCode3: "ZAF", PhoneCode: "27", IsoCode: "710" },
    { Country: "South Korea", CountryCode: "KR", CountryCode3: "KOR", PhoneCode: "82", IsoCode: "410" },
    // south sudan
    {
      Country: "Spain",

      CountryCode: "ES",
      CountryCode3: "ESP",
      PhoneCode: "34",
      IsoCode: "724",
      AllowRestricted: true,
      Currency: CurrencyEnum.EUR
    },
    { Country: "Sri Lanka", CountryCode: "LK", CountryCode3: "LKA", PhoneCode: "94", IsoCode: "144" },
    { Country: "Sudan", CountryCode: "SD", CountryCode3: "SDN", PhoneCode: "249", IsoCode: "736" },
    { Country: "Suriname", CountryCode: "SR", CountryCode3: "SUR", PhoneCode: "597", IsoCode: "740" },
    { Country: "Svalbard", CountryCode: "SJ", CountryCode3: "SJM", PhoneCode: "", IsoCode: "744" },
    { Country: "Swaziland", CountryCode: "SZ", CountryCode3: "SWZ", PhoneCode: "268", IsoCode: "748" },
    {
      Country: "Sweden",

      CountryCode: "SE",
      CountryCode3: "SWE",
      PhoneCode: "46",
      IsoCode: "752",
      AllowRestricted: true
    },
    {
      Country: "Switzerland",

      CountryCode: "CH",
      CountryCode3: "CHE",
      PhoneCode: "41",
      IsoCode: "756",
      AllowRestricted: false
    },
    { Country: "Syria", CountryCode: "SY", CountryCode3: "SYR", PhoneCode: "963", IsoCode: "760" },
    { Country: "Taiwan", CountryCode: "TW", CountryCode3: "TWN", PhoneCode: "886", IsoCode: "158" },
    { Country: "Tajikistan", CountryCode: "TJ", CountryCode3: "TJK", PhoneCode: "992", IsoCode: "762" },
    { Country: "Tanzania", CountryCode: "TZ", CountryCode3: "TZA", PhoneCode: "255", IsoCode: "834" },
    { Country: "Thailand", CountryCode: "TH", CountryCode3: "THA", PhoneCode: "66", IsoCode: "764" },
    { Country: "Timor-Leste", CountryCode: "TL", CountryCode3: "TLS", PhoneCode: "670", IsoCode: "626" },
    { Country: "Togo", CountryCode: "TG", CountryCode3: "TGO", PhoneCode: "228", IsoCode: "768" },
    { Country: "Tokelau", CountryCode: "TK", CountryCode3: "TKL", PhoneCode: "690", IsoCode: "772" },
    { Country: "Tonga", CountryCode: "TO", CountryCode3: "TON", PhoneCode: "676", IsoCode: "776" },
    {
      Country: "Trinidad and Tobago",

      CountryCode: "TT",
      CountryCode3: "TTO",
      PhoneCode: "1 868",
      IsoCode: "780"
    },
    { Country: "Tunisia", CountryCode: "TN", CountryCode3: "TUN", PhoneCode: "216", IsoCode: "788" },
    { Country: "Turkey", CountryCode: "TR", CountryCode3: "TUR", PhoneCode: "90", IsoCode: "792" },
    { Country: "Turkmenistan", CountryCode: "TM", CountryCode3: "TKM", PhoneCode: "993", IsoCode: "795" },
    {
      Country: "Turks and Caicos Islands",

      CountryCode: "TC",
      CountryCode3: "TCA",
      PhoneCode: "1 649",
      IsoCode: "796"
    },
    { Country: "Tuvalu", CountryCode: "TV", CountryCode3: "TUV", PhoneCode: "688", IsoCode: "798" },
    { Country: "Uganda", CountryCode: "UG", CountryCode3: "UGA", PhoneCode: "256", IsoCode: "800" },
    { Country: "Ukraine", CountryCode: "UA", CountryCode3: "UKR", PhoneCode: "380", IsoCode: "804" },
    {
      Country: "United Arab Emirates",

      CountryCode: "AE",
      CountryCode3: "ARE",
      PhoneCode: "971",
      IsoCode: "784"
    },
    {
      Country: "United States of America",

      CountryCode: "US",
      CountryCode3: "USA",
      PhoneCode: "1",
      IsoCode: "840",
      AllowRestricted: false,
      Currency: CurrencyEnum.USD,
      Alternatives: ["U.S.A", "U.S."]
    },
    { Country: "Uruguay", CountryCode: "UY", CountryCode3: "UMI", PhoneCode: "598", IsoCode: "581" },
    {
      Country: "US Virgin Islands",

      CountryCode: "VI",
      CountryCode3: "URY",
      PhoneCode: "1 340",
      IsoCode: "858"
    },
    { Country: "Uzbekistan", CountryCode: "UZ", CountryCode3: "UZB", PhoneCode: "998", IsoCode: "860" },
    { Country: "Vanuatu", CountryCode: "VU", CountryCode3: "VUT", PhoneCode: "678", IsoCode: "548" },
    { Country: "Venezuela", CountryCode: "VE", CountryCode3: "VEN", PhoneCode: "58", IsoCode: "862" },
    { Country: "Vietnam", CountryCode: "VN", CountryCode3: "VNM", PhoneCode: "84", IsoCode: "704" },
    {
      Country: "Wallis and Futuna",

      CountryCode: "WF",
      CountryCode3: "WLF",
      PhoneCode: "681",
      IsoCode: "876"
    },
    //      {Country: "West Bank", CountryCode: ", CountryCode3: "WLF", PhoneCode: "970", IsoCode: "876"},
    { Country: "Western Sahara", CountryCode: "EH", CountryCode3: "ESH", PhoneCode: "", IsoCode: "732" },
    { Country: "Yemen", CountryCode: "YE", CountryCode3: "YEM", PhoneCode: "967", IsoCode: "887" },
    { Country: "Zambia", CountryCode: "ZM", CountryCode3: "ZMB", PhoneCode: "260", IsoCode: "894" },
    { Country: "Zimbabwe", CountryCode: "ZW", CountryCode3: "ZWE", PhoneCode: "263", IsoCode: "004" }
  ]
};
