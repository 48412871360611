export default {
  Enum: {
    Title: 1,
    Religion: 2,
    Gender: 3,
    Relationship: 4,
    Contactable: 5,
    Responsibility: 6,
    MaritalStatus: 7,
    Ethnicity: 8,
    Nationality: 9,
    MedicalHistory: 10,
    CohenMansfield: 11,
    RiskCategory: 12,
    IntimateCare: 13,
    SurgicalHistory: 14,
    Allegeries: 15,
    OralCavity: 16,
    WoundType: 17,
    WoundLocation: 18,
    HealingFactorLocal: 19,
    FundingType: 20,
    HealingFactorSystemic: 21,
    Hospitals: 22,
    Carehomes: 23,
    AdmitFromOther: 24
  }
};
