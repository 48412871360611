<!-- Cancel and Save buttons
     Use with Standard form that needs control of the Submit process
     Otherwise use XyeaForm that has own version of this
-->

<template>
  <div class="level has-text-centered scrollPaneFooter fullWidth is-mobile" :class="{ asideVisible: isAsideMobileExpanded }">
    <div class="level-item">
      <router-link v-if="cancelLink" :to="cancelLink" class="button">Cancel</router-link>
      <b-button v-else @click="close">Close</b-button>
    </div>
    <div class="level-item" v-if="!disabled">
      <b-button type="is-primary" :loading="isSaving" @click="submit">Save</b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    isSaving: { type: Boolean, required: false },
    disabled: { type: Boolean },
    cancelLink: { type: String }
  },
  computed: {
    ...mapState(["isAsideMobileExpanded"])
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    close() {
      this.$router.go(-1);
    }
  }
};
</script>
