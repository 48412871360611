<template>
  <lookup-select-add :value="value" @input="v => $emit('input', v)" title="Title" :lookupType="lookupType" :disabled="disabled" />
</template>

<script>
import LookupSelectAdd from "@/components/LookupSelectAdd.vue";

export default {
  components: { LookupSelectAdd },
  values: {
    dr: "Dr"
  },
  props: ["value", "disabled"],
  data() {
    return {
      lookupType: LookupSelectAdd.Enum.Title
    };
  }
};
</script>
