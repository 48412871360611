<template>
  <div>
    <XyeaFormEdit
      icon="image-size-select-large"
      entityName="Image"
      @close="$emit('close')"
      :dialog="true"
      :canSaveNewNotDirty="true"
      :useCustomSave="true"
      @save="save"
    >
      <p class="label unitLabel">Use mouse / fingers to Pan and Zoom image or the controls below</p>

      <clipper-fixed class="my-clipper" :src="imgURL" ref="clipper" :round="true" :area="65" :rotate="rotate" :handleZoomEvent="zoomed">
        <div class="placeholder" slot="placeholder">No image</div>
      </clipper-fixed>
      <div class="level narrow is-mobile">
        <p class="label unitLabel">Scale</p>
        <b-slider v-model="scale" :min="minScale" :max="maxScale" :step="0.01" type="is-dark" />
      </div>
      <div class="level is-mobile">
        <p class="label unitLabel">Rotate</p>
        <b-slider v-model="rotate" :min="-180" :max="180" type="is-dark" />
      </div>
    </XyeaFormEdit>
  </div>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";

export default {
  components: { XyeaFormEdit },
  props: {
    imgURL: { type: String, required: true }
  },
  data() {
    return {
      rotate: 0,
      scale: 1,
      minScale: 0.1,
      maxScale: 5
    };
  },
  watch: {
    scale(val) {
      this.$refs.clipper.setWH$.next(+val);
    }
  },
  methods: {
    zoomed(scale) {
      if (!isNaN(scale)) {
        scale = Math.max(Math.min(this.maxScale, scale), this.minScale);
        this.scale = scale;
      }
      return scale;
    },

    save() {
      const canvas = this.$refs.clipper.clip(); // call component's clip method
      const resultURL = canvas.toDataURL("image/jpeg", 1); // canvas->image

      this.$emit("newImage", resultURL);
    }
  }
};
</script>
