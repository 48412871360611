<template>
  <div>
    <b-field :label="label" horizontal class="field-nested">
      <b-field :message="errorMsg" :type="{ 'is-danger': errorMsg }">
        <VuePhoneNumberInput
          :value="phoneNumber"
          :required="required"
          :default-country-code="currentCountryCode"
          :preferred-countries="['IE', 'GB']"
          @update="updateModel"
          :no-example="true"
          :disabled="disabled"
        />
      </b-field>
    </b-field>
  </div>
</template>

<script>
/* eslint-disable import/first */
const VuePhoneNumberInput = () => import(/* webpackChunkName: "VuePhoneNumberInput" */ "vue-phone-number-input");
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  props: ["phoneNumber", "countryCode", "label", "required", "disabled"],
  data() {
    return {
      isError: false
    };
  },
  computed: {
    currentCountryCode() {
      return this.countryCode || this.$store.getters.defaultCountryCode;
    },
    errorMsg() {
      return this.isError ? "That number is not correct, please re-check it" : "";
    }
  },
  components: { VuePhoneNumberInput },
  methods: {
    updateModel(payload) {
      this.isError = !payload.isValid && payload.phoneNumber;
      this.$emit("updatePhone", {
        countryCode: payload.countryCode,
        phoneNumber: payload.nationalNumber || payload.phoneNumber,
        isError: this.isError
      });
    }
  }
};
</script>
